import Lottie from 'react-lottie'
import { useInView } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import React, { useEffect, useRef, useState } from 'react'
import AOS from 'aos'

import * as HeroAnimationDesktop from 'assets/animations/hero-animation-desktop.json'
import * as HeroAnimationMobile from 'assets/animations/hero-animation-mobile.json'

const ImageAnimation = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const [isStopped, setIsStopped] = useState(true)

  const refAnimation = useRef(null)

  const isInView = useInView(refAnimation, { once: false, margin: '0px 0px -50px 0px' })

  AOS.init({
    once: true
  })

  useEffect(() => {
    setTimeout(() => {
      if (isInView === true) {
        return setIsStopped(false)
      }
    }, 1500)
  }, [isInView])

  const animationData = isMobile ? HeroAnimationMobile : HeroAnimationDesktop

  return (
    <div ref={refAnimation} className="relative hero-image max-w-[1200px] phone:translate-x-[5px] largeDesktop:translate-x-[30px] 3xl:translate-x-[40px]  3xl:max-w-[1400px] mx-auto phone:mt-[20px] tablet:mt-[-58px] tablet:max-w-[900px] largeDesktop:mt-[-90px] 3xl:mt-[-80px] 4k:mt-[-50px] 4k:max-w-[2400px] phone:mb-[24px]">
      <div className="hidden md:block">
        <Lottie
          isStopped={isStopped}
          options={{
            loop: false,
            autoplay: false,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
        />
      </div>
      <div className="flex justify-center w-full md:hidden">
        <img src="/assets/images/homepage-hero-mobile.png" alt="" />
      </div>
      <div data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1300" className="absolute right-[10.5rem] bottom-[9.5rem] xl:right-[11.5rem] xl:bottom-[14rem] hidden md:block w-20 xl:w-auto">
        <img src="/assets/images/recruiters.svg" className="w-[104px]" alt="recruiter" />
      </div>
      <img data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1500" src="/assets/images/person.svg" className="hidden md:block absolute right-[14rem] bottom-[1rem] xl:right-[17rem] xl:bottom-[-0.5rem] w-[140px] xl:w-auto" alt="recruiter" />
      {/* <img
            src={
              isMobile
                ? "/assets/images/landing-hero-mobile.png"
                : "/assets/images/landing-hero-desktop.png"
            }
            className="w-full"
            alt=""
          /> */}
    </div>
  )
}

export default ImageAnimation
