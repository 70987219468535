import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const CardAnimation = (cardRef: any) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    ScrollTrigger.batch(cardRef.current, {
      once: true,
      onEnter: elements => {
        gsap.from(elements, {
          autoAlpha: 0,
          y: 40,
          stagger: 0.175
        })
      },
      start: '-50px bottom'
    })
  })
}

export default CardAnimation
