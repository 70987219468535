import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Reveal, Timeline, Tween } from 'react-gsap'

const Interview = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const is4k = useMediaQuery({ minWidth: 3840 })

  return (
    <div className="phone:block flex items-center">
      <Reveal threshold={0.2725}>
        <Timeline
          target={
            <>
              <img
                src="/assets/images/landing-interview-desktop.png"
                alt=""
                className="w-full max-w-[739px] tablet:max-w-[661px] mt-[80px] phone:mt-[56px] 4k:max-w-[1600px]"
              />
            </>
          }
        >
          <Tween
            from={{ opacity: 0, transform: 'translateX(-30px)' }}
            duration={0.6}
          />
          <div className="ml-[119px] phone:mt-[32px] tablet:mt-[95px] tablet:ml-[48px]">
            <Timeline
              target={
                <>
                  <p className="font-bold text-h4 text-ambit-bold text-white tablet:text-bodylarge 4k:text-h1">
                    Interview
                  </p>
                  <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] mt-[16px] phone:max-w-[203px] 4k:text-h3 4k:leading-[50px]">
                    {isMobile || is4k
                      ? 'if the candidate has fulfilled the data. recruiters will directly conduct interviews with candidates'
                      : `Locking down times, aligning suitable days. Let us know your
                  availability and we'll make sure it's a seamless process meeting
                  with candidates`}
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                duration={0.6}
                target={0}
              />
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                duration={0.6}
                target={1}
              />
            </Timeline>
          </div>
        </Timeline>
      </Reveal>
    </div>
  )
}

export default Interview
