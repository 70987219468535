import React from 'react'

export const Illustration = (
  { className }:
    { className: string }
) => {
  return (
    <svg className={className} width="514" height="356" viewBox="0 0 514 356" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g clipPath="url(#clip0_3504_72536)">
        <g filter="url(#filter0_f_3504_72536)">
          <ellipse cx="256.954" cy="177.39" rx="256.363" ry="60.3708" transform="rotate(-25.9295 256.954 177.39)" fill="url(#paint0_linear_3504_72536)" fillOpacity="0.38" />
        </g>

        <image href={require('./image.png')} width="514" height="356" x="0" y="0" />
      </g>
      <defs>
        <filter id="filter0_f_3504_72536" x="-145.188" y="-117.252" width="804.284" height="589.283" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="85.0292" result="effect1_foregroundBlur_3504_72536" />
        </filter>
        <linearGradient id="paint0_linear_3504_72536" x1="431.248" y1="116.962" x2="252.844" y2="389.92" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3A6AD0" />
          <stop offset="1" stopColor="#9BB0DD" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const TabletIllustration = (
  { className }:
    { className: string }
) => {
  return (
    <svg className={className} width="514" height="356" viewBox="0 0 514 356" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g clipPath="url(#clip0_3504_72536)">
        <g filter="url(#filter0_f_3504_72536)">
          <ellipse cx="256.954" cy="177.39" rx="256.363" ry="60.3708" transform="rotate(-25.9295 256.954 177.39)" fill="url(#paint0_linear_3504_72536)" fillOpacity="0.38" />
        </g>

        <image href={require('./tabletImage.png')} width="514" height="356" x="0" y="0" />
      </g>
      <defs>
        <filter id="filter0_f_3504_72536" x="-145.188" y="-117.252" width="804.284" height="589.283" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="85.0292" result="effect1_foregroundBlur_3504_72536" />
        </filter>
        <linearGradient id="paint0_linear_3504_72536" x1="431.248" y1="116.962" x2="252.844" y2="389.92" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3A6AD0" />
          <stop offset="1" stopColor="#9BB0DD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
