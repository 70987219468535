import React from 'react'
import { Reveal, Tween } from 'react-gsap'
import { Illustration, TabletIllustration } from './Illustration'

const RecruitmentAgency = () => {
  return (
    <div className="bg-white relative z-20 py-[120px] tablet:py-[80px] phone:py-[56px]">
      <div className="max-w-[1100px] tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto flex phone:flex-col-reverse items-center largeDesktop:items-start tablet:items-center justify-center">
        <div className="phone:mt-[40px] md:mt-[30px] tablet:mt-[0px] md:max-w-[448px]">
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }}>
              <Illustration className="h-auto overflow-visible min-w-[448px] tablet:hidden block phone:w-full phone:min-w-0" />
              <TabletIllustration className="h-auto overflow-visible max-w-[362px] tablet:block hidden phone:w-full phone:min-w-0" />
            </Tween>
          </Reveal>
        </div>
        <div className="ml-[108px] phone:ml-0 tablet:max-w-[440px] largeDesktop:max-w-[544px] tablet:w-full tablet:ml-[68px]">
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateX(20px)' }}>
              <p className="text-h2 tablet:text-h3  text-secondary-950 mb-[24px] font-bold text-ambit-bold">
                A Recruitment Agency That Does What They Promise
              </p>
              <p className="max-w-[738px] tablet:max-w-[440px] text-bodymedium text-ambit-light text-secondary-900">
                Our approach to business is simple. We provide a seamless
                experience for securing the right candidates to fulfil your
                sales and marketing positions. From job position suitability
                to company culture alignment, we follow a tried and tested
                process for sourcing, filtering, interviewing and securing a
                mutually beneficial match who is going to make your customers
                happy and increase revenue for your company.
                <span className="block mt-[16px]">
                  As part of our tailored and all-inclusive recruitment
                  service, we will take care of the entire recruitment process
                  to leave you more time to focus on the things that matter –
                  like running your business. If you’re in need of a
                  <span className="font-bold text-ambit-bold">
                    {' '}
                    professional sales and marketing recruitment agency in
                    Australia{' '}
                  </span>
                  that focuses on your industry then give us a call.
                </span>
              </p>
            </Tween>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

export default RecruitmentAgency
