/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import useFixedHeader from 'components/FixedHeaderContext/useFixedHeader'
import { Team_Entity } from './data'

const TeamModal = ({
  team,
  showModal,
  onClose,
  modalRef
}: {
  team: Team_Entity | null;
  showModal: boolean;
  onClick?: () => void;
  onClose: () => void;
  modalRef: any;
}) => {
  const [ShowPhoneTooltip, setShowPhoneTooltip] = useState(false)
  const [ShowLinkedinTooltip, setShowLinkedinTooltip] = useState(false)

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('!overflow-y-hidden')
    } else {
      document.body.classList.remove('!overflow-y-hidden')
    }
  }, [showModal])

  return (
    <div
      style={{ zIndex: 9999999 }}
      className={`${showModal ? 'flex' : 'hidden'
        } bg-black/60 fixed inset-0 w-full h-full items-center phone:items-start justify-center overflow-y-scroll`}
    >
      <div
        ref={modalRef}
        style={{ zIndex: 9999999 }}
        className="phone:my-[121px] 4k:translate-y-[-3700px] bg-white p-[40px] w-full max-w-[1120px] tablet:max-w-[912px] phone:max-w-[329px] phone:p-[24px] rounded-[6px]"
      >
        <div className="top-part flex phone:flex-col-reverse items-start justify-between">
          <div className="flex">
            <div
              className="avatar w-[104px] h-[104px] tablet:w-[88px] tablet:h-[88px] phone:w-[56px] phone:h-[56px] border border-black rounded-full overflow-hidden mr-[16px]"
              style={{ background: team?.gradient }}
            >
              <img src={`/assets/images/${team?.pic}.png`} alt="" />
            </div>
            <div className="flex phone:flex-col ">
              <div className="space-y-[4px] tablet:space-y-[0px] mt-[20px] tablet:mt-[10px] phone:mt-[0px] mr-[26px] phone:mb-[12px]">
                <h3 className="text-h3 tablet:text-h4 phone:text-bodymedium text-info-pressed text-ambit-bold">
                  {team?.name}
                </h3>
                <p className="text-bodylarge tablet:text-bodymedium phone:text-caption text-ambit-light text-secondary-950">
                  {team?.title}
                </p>
              </div>
              <div className="flex items-center space-x-[16px] tablet:mt-[-34px] phone:mt-[0px]">
                <button
                  className="flex relative"
                  onMouseEnter={() => {
                    setShowPhoneTooltip(true)
                    setShowLinkedinTooltip(false)
                  }}
                >
                  <img
                    src="/assets/icons/ic-call-team.svg"
                    onClick={() => setShowPhoneTooltip(!ShowPhoneTooltip)}
                    className="phone:w-[20px]"
                    alt=""
                  />
                  {/* <img
                    src="/assets/icons/ic-arrow-down-team.svg"
                    onClick={() => setShowPhoneTooltip(!ShowPhoneTooltip)}
                    className={[
                      'phone:w-[15px]',
                      ShowPhoneTooltip ? 'rotate-[180deg]' : 'rotate-[0deg]'
                    ].join(' ')}
                    alt=""
                  /> */}
                  {ShowPhoneTooltip && (
                    <a
                      href={`tel:${team?.phone}`}
                      className="tooltip tooltip-phone -ml-5"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseLeave={() => setShowPhoneTooltip(false)}
                    >
                      <img
                        src="/assets/images/shapes/ic-rectangle.svg"
                        alt=""
                        className="translate-y-[-12px] mx-auto bg-white"
                      />
                      {team?.phone}
                    </a>
                  )}
                </button>
                {/* <button>
                  <img
                    src="/assets/icons/ic-document-team.svg"
                    className="phone:w-[20px]"
                    alt=""
                  />
                </button> */}
                <button
                  onMouseEnter={() => {
                    setShowLinkedinTooltip(true)
                    setShowPhoneTooltip(false)
                  }}
                  className="relative flex focus:outline-none focus:ring-0"
                >
                  <img
                    src="/assets/icons/linkedin-solid.svg"
                    className="phone:w-[20px]"
                    alt=""
                  />
                  {ShowLinkedinTooltip && (
                    <a
                      href={team?.linkedin}
                      className="tooltip tooltip-linkedin underline"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseLeave={() => setShowLinkedinTooltip(false)}
                    >
                      <img
                        src="/assets/images/shapes/ic-rectangle.svg"
                        alt=""
                        className="translate-y-[-12px] mx-auto bg-white"
                      />
                      {team?.name.split(' ')[0]} LinkedIn
                    </a>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="phone:text-right phone:w-full">
            <button onClick={onClose}>
              <img
                src="/assets/icons/ic-close-circle.svg"
                className="phone:w-[21.5px]"
                alt=""
              />
            </button>
          </div>
        </div>
        <div className="bg-secondary-300 h-[1px] my-[40px] tablet:mt-[56px] phone:my-[24px]"></div>
        <div className="flex phone:flex-col items-start justify-between">
          <div className="about w-[616px] tablet:w-[458px] phone:w-full">
            <h4 className="text-h4 phone:text-bodymedium text-ambit-bold text-secondary-950 mb-[16px] tablet:mb-[8px] largeDesktop:mb-[8px]">
              About
            </h4>
            <div
              className="text-bodymedium tablet:text-bodysmall text-ambit-light text-secondary-950 space-y-[8px] mb-[16px] pr-[12px] tablet:pr-[0px] about-team-detail-text phone:text-bodysmall tablet:max-w-[458px]"
              dangerouslySetInnerHTML={{
                __html: team?.info ?? ''
              }}
            ></div>
            {team?.placements && (
              <p className="text-secondary-950 font-bold text-ambit-bold text-h4 phone:text-bodymedium mb-[8px]">
                Top 5 Placements
              </p>
            )}
            {team?.placements && (
              <ul className="space-y-[12px] phone:space-y-[8px]">
                {team?.placements.split('|').map((spec, index) => {
                  return (
                    <li key={index} className="flex items-start">
                      <div className="w-[14px] h-[14px] bg-primary rounded-full flex items-center justify-center mr-[10px]">
                        <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                      </div>
                      <span className="text-bodymedium tablet:text-bodysmall text-ambit-light text-secondary-900">
                        {spec}
                      </span>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
          <div
            className="image w-[366px] tablet:w-[316px] tablet:scale-[1] tablet:translate-x-[0px]  phone:translate-y-0 phone:translate-x-0 tablet:h-[256px] phone:h-[162px] overflow-hidden phone:w-full phone:mt-[24px] phone:scale-[1] translate-x-[0px] translate-y-[0px] tablet:mb-[0px] phone:mb-[0px] rounded-[6px]"
            style={{
              background: team?.gradient
            }}
          >
            <img
              src={`/assets/images/teams/${team?.big_pic}.png`}
              className={'w-full phone:h-[162px] object-cover tablet:w-[316px] tablet:h-[256px] tablet:scale-[1] scale-[1.1] phone:scale-[1] '}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamModal
