import React from 'react'
import { Reveal, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'

const StandFor = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <div className="bg-white relative z-20 pt-[120px] pb-[84px] tablet:py-[80px] phone:py-[56px]">
      <div className="max-w-[1178px] phone:max-w-[327px] phone:w-full tablet:max-w-[912px] w-[90%] mx-auto flex phone:flex-col justify-center tablet:justify-between items-stretch">
        <Reveal>
          <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }}>
            <div className="mr-[132px] tablet:mr-[48px] phone:mr-0 max-w-[528px] tablet:max-w-[532px] phone:mb-[52px]">
              <p className="text-h2 tablet:text-h3 text-secondary-950 mb-[24px] font-bold text-ambit-bold">
                What We Stand For
              </p>
              <p className="max-w-[439px] tablet:max-w-[424px] text-bodymedium text-ambit-light text-secondary-900 mb-[32px] phone:mb-[24px]">
                Like any successful company, our values are the foundation of
                our businees. From the very principles our business is built
                on to the way we operate, our values are the essence of our
                identity.
              </p>
              <img
                src={
                  isMobile
                    ? '/assets/images/what-we-stand-for-mobile.png'
                    : '/assets/images/what-we-stand-for.png'
                }
                className="w-full tablet:max-w-[420px] phone:h-[201px] object-cover md:h-[400px] tablet:h-[340px]"
                alt="twoPerson"
              />
            </div>
          </Tween>
        </Reveal>
        <div className="grid grid-cols-2 phone:grid-cols-1 phone:gap-y-[36px] gap-[32px] max-w-[544px] tablet:max-w-[532px] phone:max-w-[100%]">
          <div className="stand-card phone:p-[24px] pt-[24px] tablet:p-[20px] pb-[44px] px-[24px] border border-black rounded-[6px]">
            <div className="w-[56px] h-[56px] tablet:w-[48px] tablet:h-[48px] rounded-full bg-[#3377FF]/[0.05] flex items-center justify-center">
              <img src="/assets/icons/ic-github.svg" alt="" className="tablet:w-[24px] tablet:h-[24px]" />
            </div>
            <p className="text-ambit-bold text-bodylarge tablet:text-bodymedium phone:text-bodylarge text-secondary-950 mt-[16px]    mb-[8px] phone:mt-[16px]">
              Integrity
            </p>
            <p className="text-secondary-950 text-bodysmall leading-5 tablet:text-caption phone:text-bodysmall text-ambit-light w-[208px] tablet:w-[164px] phone:w-[279px]">
              We pride ourselves on always acting with honesty and
              transparency. Our approach to business is genuine and we’re
              consistent in all that <span className="tablet:hidden block">we do</span> ...
            </p>
          </div>
          <div className="stand-card pt-[24px] phone:p-[24px] tablet:p-[20px] pb-[24px] pl-[24px] pr-[24px] border border-black rounded-[6px]">
            <div className="w-[56px] h-[56px] tablet:w-[48px] tablet:h-[48px] rounded-full bg-warning-main/[0.05] flex items-center justify-center">
              <img src="/assets/icons/ic-users.svg" alt="" className="tablet:w-[24px] tablet:h-[24px]" />
            </div>
            <p className="text-ambit-bold text-bodylarge tablet:text-bodymedium text-secondary-950 mt-[16px] phone:mt-[16px]    mb-[8px]">
              Client Commitment
            </p>
            <p className="text-secondary-950 text-bodysmall leading-5 tablet:text-caption tablet:max-w-[160px] phone:text-bodysmall text-ambit-light w-[208px] tablet:w-[164px] phone:w-[279px]">
              We care about each candidate and client and are committed to
              finding the right solution tailored to your needs. We don’t
              consider our valued client as just another transaction.
            </p>
          </div>
          <div className="stand-card pt-[24px] phone:p-[24px] tablet:p-[20px] pb-[64px] pl-[24px] pr-[24px] border border-black rounded-[6px]">
            <div className="w-[56px] tablet:w-[48px] tablet:h-[48px] h-[56px] rounded-full bg-success-main/[0.05] flex items-center justify-center">
              <img src="/assets/icons/ic-square.svg" alt="" className="tablet:w-[24px] tablet:h-[24px]" />
            </div>
            <p className="text-ambit-bold tablet:text-bodymedium text-bodylarge text-secondary-950 mt-[16px]  phone:mt-[16px]   mb-[8px]">
              Teamwork
            </p>
            <p className="text-secondary-950 text-bodysmall leading-5 tablet:text-caption phone:text-bodysmall text-ambit-light">
              From the way we work with our team to the way we work with
              yours, we’re fiercely dedicated to forming strong relationships.
            </p>
          </div>
          <div className="stand-card phone:p-[24px] tablet:p-[20px] pt-[24px] pb-[44px] pl-[24px] pr-[24px]  border border-black rounded-[6px]">
            <div className="w-[56px] tablet:w-[48px] tablet:h-[48px] h-[56px] rounded-full bg-primary/[0.05] flex items-center justify-center">
              <img src="/assets/icons/ic-quality.svg" alt="" className="tablet:w-[24px] tablet:h-[24px]" />
            </div>
            <p className="text-ambit-bold text-bodylarge tablet:text-bodymedium text-secondary-950 mt-[16px]   phone:mt-[16px]  mb-[8px]">
              Quality
            </p>
            <p className="text-secondary-950 text-bodysmall leading-5 tablet:text-caption phone:text-bodysmall text-ambit-light">
              As a company built on excellence, we pride ourselves on
              providing services that are equalled in expertise and
              old-fashioned customer service ...
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StandFor
