import { useCallback } from "react";

const useDownloadTemplate = (): (() => void) => {
  const downloadTemplateHandler = useCallback(() => {
    try {
      const link = document.createElement("a");
      link.href = `${process.env.PUBLIC_URL}/documents/blended-cv-template.docx`;
      link.download = "Blended Employment CV Template.docx";
      link.click();
    } catch (error) {
      alert("Error downloading the template");
      console.error("Error downloading the template:", error);
    }
  }, []);

  return downloadTemplateHandler;
};

export default useDownloadTemplate;
