import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
export default function Mobile () {
  return (
    <SkeletonTheme baseColor="#EDF1F6" highlightColor="#E3EAF3">
      <div className="skeleton-card p-[20px] bg-white rounded-[6px] border ">
        <div className="mb-[15px] flex justify-between">
          <Skeleton className="!w-[160px] !h-[32px] !rounded-[6px]" />
          <Skeleton className="!w-[60px] !rounded-[40px] !h-[32px]" />
        </div>
        <div className="flex">
          <Skeleton className="!w-[124px] !h-[16px] !rounded-[6px] !mr-[12px]" />
          <Skeleton className="!w-[92px] h-[20px] !rounded-[6px]" />
        </div>
        <div className="my-[12px] h-[1px] bg-[#EDF1F6]"></div>
        <div className="">
          <Skeleton className="!w-[132px] !h-[24px] !rounded-[6px] !mb-[14px]" />
          <Skeleton className="!w-[77px] !h-[24px] !rounded-[6px] !mb-[14px]" />
          <Skeleton className="!w-[155px] !h-[24px] !rounded-[6px] !mb-[14px]" />
        </div>
        <Skeleton className="!h-[44px] !rounded-[6px] !mt-[14px]" />
      </div>
    </SkeletonTheme>
  )
}
