import React, { Suspense, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import {
  About,
  Home,
  IndustrySpecializations,
  JobBoard,
  JobBoardDetail,
} from "./screens";

function App() {
  // window.onbeforeunload = function () {
  //   window.scrollTo(0, 0)
  // }
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Suspense
      fallback={
        <div className="absolute left-0 font-bold top-0 w-full h-full bg-white bg-opacity-80 flex items-center justify-center">
          Please Wait...
        </div>
      }
    >
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/job-board" component={JobBoard} />
          <Route path="/job-board/:job_id" component={JobBoardDetail} />
          <Route
            path="/industry-specializations"
            component={IndustrySpecializations}
          />
          <Route path="/about-us" component={About} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
