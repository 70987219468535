import axios from 'axios'
import React from 'react'
import { useFormik } from 'formik'
import { Reveal, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import { ErrorComp } from 'screens/JobBoardDetail/components/JobDesc/ApplyModal'
import * as Yup from 'yup'

const Contact = () => {
  const isDesktop = useMediaQuery({ minWidth: 1440 })
  const isTablet = useMediaQuery({ minWidth: 1024 })

  const formik = useFormik({
    initialValues: {
      reply_to: '',
      from_name: '',
      subject: '',
      message: ''
    },
    onSubmit: (values) => {
      console.log(values)
      formik.setSubmitting(true)
      axios
        .post('https://api.emailjs.com/api/v1.0/email/send', {
          service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID?.trim(),
          template_id:
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONTACT?.trim(),
          user_id: process.env.REACT_APP_EMAILJS_PUBLIC_KEY?.trim(),
          template_params: values
        })
        .then((res) => {
          console.log(res)
          formik.setValues({
            from_name: '',
            message: '',
            subject: '',
            reply_to: ''
          })
          formik.setTouched({
            from_name: false,
            message: false,
            subject: false,
            reply_to: false
          })
          formik.setSubmitting(false)
          toast.success('Your request has been sent successfully')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
        .catch((err) => {
          console.log(err)
          toast.error('Something went wrong')
          formik.setSubmitting(false)
        })
    },
    validationSchema: Yup.object({
      reply_to: Yup.string().required().email(),
      from_name: Yup.string().required(),
      subject: Yup.string().required(),
      message: Yup.string().required()
    })
  })

  return (
    <div className="pt-[80px] pb-[88px] tablet:pb-[45px] phone:pb-[80px] bg-white phone:pt-[56px]">
      <div className="max-w-[1264px] 4k:max-w-[2000px] tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto">
        <div className="flex items-start 4k:justify-between phone:flex-col-reverse">
          <Reveal threshold={0.15}>
            <Tween from={{ x: -100, opacity: 0 }} duration={1}>
              <div
                style={{
                  boxShadow: isDesktop
                    ? '-40px 40px 0px #FFC85F'
                    : isTablet
                      ? '-20px 20px 0px #FFC85F'
                      : '-16px 16px 0px #FFC85F'
                }}
                className="mr-[96px] 4k:mr-[0px] tablet:mr-[40px] phone:mr-[0px]  phone:max-w-[311px] mx-auto 4k:m-0 phone:text-center 4k:w-[50%]"
              >
                <img
                  src={
                    isDesktop
                      ? '/assets/images/landing-contact.png'
                      : isTablet
                        ? '/assets/images/landing-contact-tablet.png'
                        : '/assets/images/landing-contact.png'
                  }
                  className="w-[739px] 4k:w-[100%] tablet:w-[786px] tablet:h-[780px]"
                  alt=""
                />
              </div>
            </Tween>
            <Tween from={{ x: 100, opacity: 0 }} duration={1}>
              <div className="w-full max-w-[429px] tablet:max-w-[440px] mt-[10.5px] tablet:mt-0 4k:max-w-[40%]">
                <>
                  <h5 className="text-h3 font-bold text-ambit-bold text-secondary-950 4k:text-h1">
                    Connect with a Person Who Understands People, Today.
                  </h5>
                  <p className="text-bodysmall text-ambit-light text-secondary-900 mt-[24px] max-w-[417px] tablet:max-w-[440px] 4k:text-h4 4k:max-w-[90%]">
                    For more information on our services or to get started on
                    securing the right candidate to fulfil your greatest
                    business requirements, be sure to contact the industry
                    experts at Blended Employment on 07 3667 8895 or
                    info@blendedemployment.com.au
                  </p>
                  <div className="contact-form mt-[52px] tablet:mt-[32px]">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group mb-[24px]">
                        <label
                          htmlFor="full_name"
                          className="text-bodymedium tablet:text-bodysmall text-ambit-semibold mb-[16px] block 4k:text-h3"
                        >
                          Your Name <span className="text-danger-main">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Input your name"
                          name="from_name"
                          value={formik.values.from_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="py-[16px] px-[24px] 4k:py-[32px] 4k:px-[32px] border border-secondary-300 rounded-[8px] tablet:rounded-[16px] w-full outline-none focus:border-primary text-bodymedium text-ambit-light placehoder:text-secondary-600 4k:text-h4"
                        />
                        <ErrorComp
                          touched={formik.touched.from_name}
                          error={formik.errors.from_name}
                        />
                      </div>
                      <div className="form-group mb-[24px]">
                        <label
                          htmlFor="email"
                          className="text-bodymedium tablet:text-bodysmall text-ambit-semibold mb-[16px] block 4k:text-h3"
                        >
                          Your Email <span className="text-danger-main">*</span>
                        </label>
                        <input
                          type="text"
                          name="reply_to"
                          value={formik.values.reply_to}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Input your email"
                          className="py-[16px] px-[24px] 4k:py-[32px] 4k:px-[32px] border border-secondary-300 rounded-[8px] tablet:rounded-[16px] w-full outline-none focus:border-primary text-bodymedium text-ambit-light placehoder:text-secondary-600 4k:text-h4"
                        />
                        <ErrorComp
                          touched={formik.touched.reply_to}
                          error={formik.errors.reply_to}
                        />
                      </div>
                      <div className="form-group mb-[24px]">
                        <label
                          htmlFor="email"
                          className="text-bodymedium tablet:text-bodysmall text-ambit-semibold mb-[16px] block 4k:text-h3"
                        >
                          Subject <span className="text-danger-main">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Input subject"
                          name="subject"
                          value={formik.values.subject}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="py-[16px] px-[24px] 4k:py-[32px] 4k:px-[32px] border border-secondary-300 rounded-[8px] tablet:rounded-[16px] w-full outline-none focus:border-primary text-bodymedium text-ambit-light placehoder:text-secondary-600 4k:text-h4"
                        />
                        <ErrorComp
                          touched={formik.touched.subject}
                          error={formik.errors.subject}
                        />
                      </div>
                      <div className="form-group mb-[24px]">
                        <label className="text-bodymedium tablet:text-bodysmall text-ambit-semibold mb-[16px] block 4k:text-h3">
                          Your Message <span className="text-danger-main">*</span>
                        </label>
                        <textarea
                          name="message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Input message here"
                          className="py-[16px] px-[24px] 4k:py-[32px] 4k:px-[32px] border border-secondary-300 rounded-[8px] tablet:rounded-[16px] w-full outline-none focus:border-primary text-bodymedium tablet:text-bodysmall text-ambit-light placehoder:text-secondary-600 h-[120px] 4k:text-h4"
                        >
                          {formik.values.message}
                        </textarea>
                        <ErrorComp
                          touched={formik.touched.message}
                          error={formik.errors.message}
                        />
                        <button
                          type="submit"
                          className="py-[20px] bg-button w-full text-white text-bodymedium font-bold text-ambit-bold rounded-[6px] mt-[24px] 4k:text-h3 disabled:bg-button-disabled"
                          disabled={formik.isSubmitting}
                        >
                          {formik.isSubmitting
                            ? 'Sending Now...'
                            : 'Send email'}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              </div>
            </Tween>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

export default Contact
