import React from 'react'

const BackgroundGradient = ({ className } : {className: string}) => {
  return (
    <svg className={className} width="1440" height="1020" viewBox="0 0 1440 1020" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3106_69623)">
        <rect width="1440" height="1020" fill="#F7F8FB"/>
        <g filter="url(#filter0_f_3106_69623)">
          <path d="M-499.298 2416.68L-605.839 1278.3C-642.429 887.345 -333.607 550.203 59.0473 552.435L712.74 556.152L1269.52 201.566C1477.33 69.2266 1594.78 -167.292 1574.62 -412.833L1493.73 -1398.15L2193.08 -514.464C2538.68 -77.7741 2225.52 564.755 1668.63 561.588L712.74 556.152L129.206 927.774C-3.10482 1012.04 -101.312 1140.42 -148.01 1290.17L-499.298 2416.68Z" fill="url(#paint0_linear_3106_69623)" fillOpacity="0.2"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_f_3106_69623" x="-808.786" y="-1598.15" width="3346.46" height="4214.83" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_3106_69623"/>
        </filter>
        <linearGradient id="paint0_linear_3106_69623" x1="1511.64" y1="-1407.21" x2="-1072.72" y2="1779.23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0B2C72"/>
          <stop offset="1" stopColor="#86AAF7"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BackgroundGradient
