import React from 'react'

interface Props {
  label: string;
  onClick(): void;
}

const ToogleMobile = ({ onClick, label }: Props) => {
  return (
    <button
      onClick={onClick}
      className="underline decoration-info-main text-ambit-semibold text-[16px] text-info-main mt-[41px]"
    >
      {label}
    </button>
  )
}

export default ToogleMobile
