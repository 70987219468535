import useSWR from "swr";
import { fetcher } from "..";

const useJobs = (params: any) => {
  const { data, error } = useSWR(`/jobs${params}`, fetcher);
  console.log(data);
  return {
    jobs: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useJobs;
