import React from 'react'

import { CheckIcon } from '@heroicons/react/solid'

interface CheckboxProps {
  active?: boolean;
  onClick?: () => void;
}
const Checkbox = ({ active = false, onClick }: CheckboxProps) => {
  return (
    <button
      className={[
        'w-[20px] 4k:w-[30px] 4k:h-[30px] flex items-center justify-center h-[20px] text-white rounded-[3px] checkbox',
        active ? 'bg-button' : 'bg-white border-2 border-secondary-300'
      ].join(' ')}
      onClick={onClick}
    >
      <CheckIcon
        className={[
          'w-full rounded-lg w-[14px]',
          active ? ' text-white' : 'text-transparent'
        ].join(' ')}
      />
    </button>
  )
}

export default Checkbox
