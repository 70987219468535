import clsx from 'clsx'
import React from 'react'

interface Props {
  location: string,
  locations: {
    id: number,
    name: string
  }[],
  setLocation(e: any): void
}

const SelectLocation = ({ location, locations, setLocation }: Props) => {
  return (
    <div className="relative bg-white tablet:w-full phone:pr-4 flex items-center space-x-3 px-[18px] phone:h-[64px]">
      {/* Maps Icon */}
      <div className="">
        <img src="/assets/icons/ic-map.svg" alt="" />
      </div>

      <div className="flex items-center justify-between w-full space-x-1 md:justify-start">
        <select
          value={location ?? ''}
          // placeholder="Select location"
          onChange={(e: any) => {
            setLocation(e.target.value)
          }}
          className={clsx(
            'bg-transparent text-secondary-600',
            // 'pl-[48px] pr-[19px]',
            // 'pt-[20px] pb-[14px] phone:py-[22px]',
            // 'my-[16px] tablet:my-[9px]',
            'outline-none',
            'block',
            'text-bodysmall text-ambit-light phone:w-full'
          )}
        >
          <option value="">Select location</option>
          {locations.map((location) => {
            return (
              <option key={location.id} value={location.name}>
                {location.name}
              </option>
            )
          })}
        </select>

        {/* Arrow Icon */}
        <svg className="" width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00245 11.1998C7.53579 11.1998 7.06912 11.0198 6.71579 10.6665L2.36912 6.31981C2.17579 6.12647 2.17579 5.80647 2.36912 5.61314C2.56245 5.41981 2.88245 5.41981 3.07579 5.61314L7.42245 9.95981C7.74245 10.2798 8.26245 10.2798 8.58245 9.95981L12.9291 5.61314C13.1225 5.41981 13.4425 5.41981 13.6358 5.61314C13.8291 5.80647 13.8291 6.12647 13.6358 6.31981L9.28912 10.6665C8.93579 11.0198 8.46912 11.1998 8.00245 11.1998Z" fill="#BABBBF" />
        </svg>
      </div>
    </div>
  )
}

export default SelectLocation
