import clsx from 'clsx'
import React from 'react'

const SectorCard = ({
  refContainer,
  name,
  title,
  icon
}: {
  refContainer: any,
  name: string;
  title?: string;
  icon: string;
}) => {
  return (
    <div ref={refContainer} className={clsx(
      'phone:px-[13.5px] px-[23px]',
      'phone:h-[64px] h-[78px]',
      'bg-[#F7F8FB] grid phone:grid-cols-1 phone:min-w-[156px] grid-cols-[32px_1fr] gap-x-[16px] items-center'
    )}>
      <div className="icon p-[4px] rounded-[4px] border border-black w-[32px] h-[32px] text-center flex items-center phone:hidden">
        <img className="w-[20px]" src={`/assets/icons/${icon}.svg`} alt="" />
      </div>
      <div>
        <p
          className="text-bodysmall phone:text-caption font-semibold text-ambit-semibold"
          style={{ fontWeight: 600 }}
        >
          {name} <span className="text-secondary-400 ml-[4px]">{title}</span>
        </p>
      </div>
    </div>
  )
}

export default SectorCard
