import React, { useEffect } from 'react'

export default function ImageModal ({
  imageSrc,
  showModal,
  onClick
}: {
  imageSrc?: string;
  showModal: boolean;
  onClick: () => void;
}) {
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-y-hidden')
    } else {
      document.body.classList.remove('overflow-y-hidden')
    }
  }, [showModal])

  return (
    <div
      onClick={onClick}
      style={{ zIndex: 999999 }}
      className={`${
        showModal ? 'flex' : 'hidden'
      } bg-black/30 fixed  inset-0 w-full h-[full] items-center justify-center`}
    >
      <img
        onClick={() => window.location.assign('/industry-specializations')}
        src={`/assets/images/${imageSrc}`}
        className={'max-w-[1120px] 4k:w-full 4k:max-w-[2200px] tablet:max-w-[595px] phone:max-w-[327px] transition-all cursor-pointer'}
        alt=""
      />
    </div>
  )
}
