import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function LandingShape () {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const is4k = useMediaQuery({ minWidth: 3840 })
  if (isMobile) {
    return (
      <svg
        width="30"
        height="110"
        viewBox="0 0 12 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6" cy="6" r="4" fill="white" fillOpacity="0.86" />
        <line
          x1={6}
          y1={11}
          x2={6}
          y2={100}
          stroke="white"
          strokeOpacity="0.56"
          strokeWidth="2"
          style={{
            strokeDasharray: '89 89',
            strokeDashoffset: 89
          }}
          id="landing-straight-mobile"
        />
        <circle
          cx="6"
          cy="96"
          r="4"
          fill="white"
          fillOpacity="0"
          id="landing-straight-circle-end-mobile"
        />
      </svg>
    )
  }
  return (
    <svg
      height={is4k ? '100%' : '99'}
      width={'100%'}
      viewBox="0 0 499 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="493.5" cy="5.5" r="5.5" fill="white" fillOpacity="0.56" />
      <circle
        cx="493.5"
        cy="5.5"
        r="3"
        stroke="white"
        strokeOpacity="0.56"
        strokeWidth="5"
      />
      <circle cx="5.5" cy="93.5" r="5.5" fill="white" fillOpacity="0.56" />
      <circle
        cx="5.5"
        cy="93.5"
        r="3"
        stroke="white"
        strokeOpacity="0.56"
        strokeWidth="5"
      />
      <path
        id="landing-path"
        d="M494 11V30.5C494 30.5 493.809 35.0505 492 38C489.448 42.1613 483.5 46 479.5 46C475.828 46 87.1297 45.9308 22 46C16.173 46.0062 13 48 11.5 49C9.99999 50 5.16098 55.8138 5 60C4.83902 64.1862 5.00001 88.5 5.00001 88.5"
        stroke="white"
        strokeOpacity="0.56"
        strokeWidth="2"
      />
    </svg>
  )
}
