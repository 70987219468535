import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import { getCountingCategories, getCountingLocation } from 'screens/JobBoard/infrastructure/api'

import Checkbox from './Checkbox'

import FilterButton from './FilterButton'

const FilterCardMobile = ({
  selectedLocations,
  selectedCategories,
  handleSelectFilter
}: {
  selectedLocations: Array<number>;
  selectedCategories: Array<number>;
  handleSelectFilter: (id: number, filterType:string) => void;
}) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const [jobCategories, setJobCategories] = useState([])
  const [jobLocations, setJobLocations] = useState([])

  useEffect(() => {
    getCountingCategories().then((res: any) => setJobCategories(res))
    getCountingLocation().then((res: any) => setJobLocations(res))
  }, [])

  return (
    <div className={clsx(
      'phone:block hidden',
      'mb-6',
      'overflow-hidden',
      'transition-all duration-[500ms] ease-in-out',
      isOpenFilter ? 'max-h-[1000px]' : 'max-h-12'
    )}>
      <FilterButton
        isOpenFilter={isOpenFilter}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      />

      {/* Filter Business Sectors */}
      <div className="mt-6">
        <p className="text-bodylarge tablet:text-bodymedium text-ambit-semibold font-semibold text-secondary-950 mb-[16px] 4k:text-h2">
          Business sectors
        </p>
        {jobCategories.length > 0 &&
          // eslint-disable-next-line array-callback-return
          jobCategories.map((item: any, index) => {
            if (item) {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between py-[12px] 4k:py-[24px]"
                  onClick={() => handleSelectFilter(item.id, 'category')}
                >
                  <div className="flex items-center">
                    <Checkbox
                      active={
                        selectedCategories.length > 0 &&
                        selectedCategories.includes(item.id)
                      }
                    />
                    <p className="ml-[16px] text-bodymedium tablet:text-bodysmall text-ambit-semibold font-semibold text-secondary-950 mr-[24px] tablet:mr-[13px] 4k:text-h3">
                      {item.name}
                    </p>
                  </div>
                  <p className="text-ambit-light tablet:text-bodysmall 4k:text-h3 text-[#76767E]">
                    {item?.total_count ?? 0}
                  </p>
                </div>
              )
            }
          })
        }
      </div>

      <div className=" py-[24px] 4k:py-[54px]">
        <span className="h-[1px] bg-secondary-300 block"></span>
      </div>

      {/* Filtest Job Locations */}
      <p className="text-bodylarge tablet:text-bodymedium text-ambit-semibold font-semibold text-secondary-950 mb-[16px] 4k:text-h2">
        Job Locations
      </p>
      {jobLocations.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="flex items-center justify-between py-[12px] 4k:py-[24px]"
            onClick={() => handleSelectFilter(item.address, 'location')}
          >
            <div className="flex items-center">
              <Checkbox active={selectedLocations?.includes(item.address) ?? false} />
              <p className="ml-[16px] text-bodymedium tablet:text-bodysmall text-ambit-semibold font-semibold text-secondary-950  4k:text-h3">
                {item.address}
              </p>
            </div>
            <p className="text-ambit-light tablet:text-bodysmall  4k:text-h3 text-[#76767E]">
              {item.count}
            </p>
          </div>
        )
      })}

      <button
        onClick={() => {
          handleSelectFilter(0, '')
          setIsOpenFilter(false)
        }}
        className={clsx(
          'mt-6',
          'flex',
          'justify-center',
          'w-full',
          'h-12',
          'rounded-md',
          'bg-[#0C15A7]',
          'text-white',
          'text-ambit-semibold',
          'items-center',
          'text-base'
        )}
      >
        Show Result
      </button>

    </div>
  )
}

export default FilterCardMobile
