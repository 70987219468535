import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "./data";

import useCloseClickOutside from "utils/useCloseClickOutside";

export default function MobileHeader({
  isFixedHeader,
}: {
  isFixedHeader: boolean;
}) {
  const wrapperRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const activePage = useLocation();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useCloseClickOutside(wrapperRef, setShowMenu);

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [showMenu]);

  return (
    <>
      <header
        ref={wrapperRef}
        className={[
          "w-full",
          isFixedHeader
            ? "fixed top-[0px] phone:max-w-[387px] left-0 right-0 mx-auto "
            : "relative",
        ].join(" ")}
        style={{ zIndex: 50 }}
      >
        <div className="header-wrapper py-[8px] px-[16px] my-[16px] mx-[24px] bg-white flex items-center justify-between">
          <Link to="/">
            <img src="/assets/images/logo.png" className="w-[98px]" alt="" />
          </Link>
          <button className="w-[24px] h-[24px]" onClick={toggleMenu}>
            <img
              src={
                showMenu
                  ? "/assets/icons/ic-x.svg"
                  : "/assets/icons/ic-menu.svg"
              }
              alt=""
            />
          </button>
        </div>
        <div
          className={[
            "mobile-menu mx-auto left-0 right-0 py-[20px] px-[16px] w-[86%] absolute bg-white !z-50",
            showMenu ? "block" : "hidden",
          ].join(" ")}
        >
          <div className="">
            <ul>
              {menus.map((menu) => {
                return (
                  <li className="mb-[27px]" key={menu.id}>
                    <Link
                      to={menu.link}
                      className={[
                        "text-bodysmall text-ambit-semibold ",
                        menu.link === activePage.pathname
                          ? "text-info-main"
                          : "text-secondary-600",
                      ].join(" ")}
                    >
                      {menu.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="h-[1px] w-full bg-secondary-300 "></div>
          <div className="mt-[29px]">
            <ul>
              <li className="mb-[21px]">
                <a
                  href="telp:0736678895"
                  className="text-bodysmall text-black font-bold mr-[24px] tablet:mr-[10px] text-bodysmall flex items-center"
                >
                  <img
                    src="/assets/icons/ic-australia.svg"
                    className="w-[24px] mr-[8px] tablet:mr-[10px] inline-block"
                    alt=""
                  />
                  07 3667 8895
                </a>
              </li>
              <li>
                <a
                  href="https://calendly.com/jordan-gko4"
                  target="_blank"
                  className="block text-center py-[10px] w-full text-bodysmall text-ambit-bold font-bold bg-primary text-white rounded-[6px]"
                  rel="noreferrer"
                >
                  Book a meeting
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div
        style={{
          background: "rgba(13, 31, 66, 0.32)",
          backdropFilter: "blur(12px)",
        }}
        className={[
          "backdrop fixed z-40 inset-0 w-full h-[100%] ",
          showMenu ? "block" : "hidden",
        ].join(" ")}
      ></div>
    </>
  );
}
