/* eslint-disable camelcase */
export interface Client_Entity {
  id: number;
  name: string;
  image: string;
}
export const clients_mobile: Array<Client_Entity> = [
  {
    id: 1,
    name: 'vimg',
    image: 'client-1.svg'
  },
  {
    id: 2,
    name: 'gurner',
    image: 'client-3.svg'
  },
  {
    id: 3,
    name: 'unkown',
    image: 'client-4.svg'
  },
  {
    id: 4,
    name: 'outfit',
    image: 'client-7.svg'
  },
  {
    id: 5,
    name: 'rexrotn',
    image: 'client-6.svg'
  },
  {
    id: 6,
    name: 'cru',
    image: 'client-8.svg'
  },
  {
    id: 7,
    name: 'avr',
    image: 'client-15.svg'
  },
  {
    id: 8,
    name: 'sterland',
    image: 'client-10.svg'
  },
  {
    id: 9,
    name: 'audience group',
    image: 'client-9.svg'
  },
  {
    id: 10,
    name: 'euromonitor-internation',
    image: 'client-13.svg'
  },
  {
    id: 11,
    name: 'flow-power',
    image: 'client-14.svg'
  },
  {
    id: 12,
    name: 'petro-industrial',
    image: 'client-16.svg'
  },
  {
    id: 13,
    name: 'southland',
    image: 'client-18.svg'
  },
  {
    id: 14,
    name: 'rt',
    image: 'client-17.svg'
  },
  {
    id: 15,
    name: 'westlab',
    image: 'client-19.svg'
  },
  {
    id: 16,
    name: 'coolon-lighting',
    image: 'client-20.svg'
  },
  {
    id: 17,
    name: 'coolon',
    image: 'client-21.svg'
  },
  {
    id: 18,
    name: 'dksh',
    image: 'client-25.svg'
  },
  {
    id: 19,
    name: 'td-williamson',
    image: 'client-24.svg'
  },
  {
    id: 20,
    name: 'azapak',
    image: 'client-28.svg'
  },
  {
    id: 21,
    name: 'choiceenerge',
    image: 'client-29.svg'
  },
  {
    id: 22,
    name: '000',
    image: 'client-30.svg'
  },
  {
    id: 23,
    name: 'components-only',
    image: 'client-31.svg'
  },
  {
    id: 24,
    name: 'dominant',
    image: 'client-32.svg'
  },
  {
    id: 25,
    name: 'fienza',
    image: 'client-33.svg'
  },
  {
    id: 26,
    name: 'cult',
    image: 'client-35.svg'
  }
]
export const clients: Array<Client_Entity> = [
  {
    id: 1,
    name: 'vimg',
    image: 'client-1.svg'
  },
  {
    id: 3,
    name: 'gurner',
    image: 'client-3.svg'
  },
  {
    id: 4,
    name: 'unkown',
    image: 'client-4.svg'
  },

  // {
  //   id: 5,
  //   name: "foxo",
  //   image: "client-5.svg",
  // },

  {
    id: 6,
    name: 'rexrotn',
    image: 'client-6.svg'
  },
  {
    id: 8,
    name: 'cru',
    image: 'client-8.svg'
  },
  {
    id: 7,
    name: 'outfit',
    image: 'client-7.svg'
  },

  {
    id: 9,
    name: 'audience group',
    image: 'client-9.svg'
  },
  {
    id: 10,
    name: 'sterland',
    image: 'client-10.svg'
  },
  // {
  //   id: 11,
  //   name: "machines4u",
  //   image: "client-11.svg",
  // },
  // {
  //   id: 12,
  //   name: "devote-digital",
  //   image: "client-12.svg",
  // },
  {
    id: 13,
    name: 'euromonitor-internation',
    image: 'client-13.svg'
  },
  {
    id: 14,
    name: 'avr',
    image: 'client-14.svg'
  },
  {
    id: 15,
    name: 'fluid-control',
    image: 'client-15.svg'
  },
  {
    id: 16,
    name: 'petro-industrial',
    image: 'client-16.svg'
  },
  {
    id: 18,
    name: 'southland',
    image: 'client-18.svg'
  },

  {
    id: 17,
    name: 'rt',
    image: 'client-17.svg'
  },
  {
    id: 19,
    name: 'westlab',
    image: 'client-19.svg'
  },
  {
    id: 20,
    name: 'coolon-lighting',
    image: 'client-20.svg'
  },
  {
    id: 21,
    name: 'coolon',
    image: 'client-21.svg'
  },
  {
    id: 40,
    name: 'dksh',
    image: 'client-25.svg'
  },
  {
    id: 24,
    name: 'td-williamson',
    image: 'client-24.svg'
  },
  // {
  //   id: 25,
  //   name: "markinson",
  //   image: "client-26.svg",
  // },
  // {
  //   id: 26,
  //   name: "the-thoughtfull-agency",
  //   image: "client-27.svg",
  // },
  {
    id: 27,
    name: 'azapak',
    image: 'client-28.svg'
  },
  {
    id: 28,
    name: 'choiceenergy',
    image: 'client-29.svg'
  },
  {
    id: 29,
    name: '000',
    image: 'client-30.svg'
  },
  {
    id: 30,
    name: 'components-only',
    image: 'client-31.svg'
  },
  {
    id: 31,
    name: 'dominant',
    image: 'client-32.svg'
  },
  {
    id: 32,
    name: 'fienza',
    image: 'client-33.svg'
  },
  // {
  //   id: 33,
  //   name: "stairlock",
  //   image: "client-34.svg",
  // },
  {
    id: 34,
    name: 'cult',
    image: 'client-35.svg'
  }
  // {
  //   id: 35,
  //   name: "unknown-2",
  //   image: "client-36.svg",
  // },
]
