import React from 'react'
import { Reveal, Timeline, Tween } from 'react-gsap'
import { Illustration, TabletIllustration } from './Illustration'

const Represent = () => {
  return (
    <div className="bg-white relative z-20 py-[104px] tablet:py-[80px] phone:py-[56px]">
      <div className="max-w-[1178px] w-[90%] tablet:max-w-[912px] phone:max-w-[327px] mx-auto flex phone:flex-col items-center justify-center tablet:justify-between">
        <div className="mr-[40px] tablet:mr-[0px] w-full tablet:max-w-[440px] max-w-[544px] ">
          <Reveal>
            <Timeline
              target={
                <>
                  <p className="text-h2 tablet:text-h3 text-secondary-950 mb-[24px] font-bold text-ambit-bold">
                    What We Represent
                  </p>
                  <p className="text-bodymedium text-ambit-light text-secondary-900">
                    Much like the people who represent Blended Employment,
                    we’re an authentic and transparent recruitment team
                    dedicated to finding the right candidates to represent
                    your business and take it to the next level.
                    <span className="block mt-[16px]">
                      We are driven by the return on investment you will get
                      from working with us, we’re passionate about the
                      services we offer and the impact a strong sales and
                      marketing team can make on your business. As a{' '}
                      <span className="font-bold phone:font-normal text-ambit-bold text-ambit-light-phone">
                        {' '}
                        professional sales and marketing recruitment agency in
                        Australia
                      </span>
                      , your success is our greatest success.
                    </span>
                  </p>
                </>
              }
            >
              <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }} />
            </Timeline>
          </Reveal>
        </div>
        <div>
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateX(20px)' }}>
              <Illustration className="w-full h-auto overflow-visible 3xl:min-w-[573px] phone:mt-[40px] tablet:hidden block" />
              <TabletIllustration className="w-full h-auto overflow-visible phone:mt-[40px] hidden tablet:block tablet:max-w-[386px]" />
            </Tween>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

export default Represent
