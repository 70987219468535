import { FixedHeader, Footer, Header } from "components";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useJobs } from "screens/JobBoard/infrastructure/api/hooks";
import JobContact from "./components/JobContact";
import JobDesc from "./components/JobDesc";
import ApplyModal from "./components/JobDesc/ApplyModal";

const JobBoardDetail: React.FC = () => {
  const params = useParams<any>();
  // eslint-disable-next-line no-unused-vars
  const { jobs, isLoading, isError } = useJobs(`/${params.job_id}`);

  const [showApplyModal, setShowApplyModal] = useState<boolean>(false);

  useEffect(() => {
    if (showApplyModal) {
      console.log(showApplyModal);
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [showApplyModal]);

  return (
    <div className="mt-[32px] phone:mt-[16px] !overflow-hidden w-full">
      <Header />
      <div className="relative">
        <img
          src="/assets/images/shapes/landing-hero-shape.svg"
          className="absolute 4k:fixed top-[-485px] scale-x-[3] tablet:scale-x-[2.8] phone:scale-[3] phone:top-[183px] tablet:top-[-200px] z-0"
          style={{ zIndex: 0 }}
          alt=""
        />
      </div>
      <div className="relative z-10 4k:scale-[2.4] 4k:translate-y-[2400px] 4k:h-[3200px]">
        <div className="relative z-10 max-w-[1120px] w-[90%] tablet:max-w-[912px] mx-auto phone:mt-[40px] mt-[80px]">
          <Link
            to="/job-board"
            className="text-warning-main text-bodymedium font-semibold text-ambit-semibold block mb-[56px]"
          >
            <img
              src="/assets/icons/ic-arrow-warning.svg"
              className="inline-block mr-[10px]"
              alt=""
            />{" "}
            Back to jobs page
          </Link>
        </div>
        <div className="bg-white relative z-50 mt-[226px] tablet:mt-[200px] phone:mt-[300px]">
          <div className="max-w-[1120px] tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto">
            <div className="flex phone:flex-col justify-between items-start translate-y-[-156px] phone:translate-y-[-270px] mb-[-40px] tablet:mb-[-75px] phone:mb-[-205px]">
              <JobDesc
                data={jobs}
                isLoading={isLoading}
                setShowApplyModal={setShowApplyModal}
              />
              <JobContact data={jobs} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <FixedHeader />
      <ApplyModal
        job_id={params.job_id}
        setShowApplyModal={setShowApplyModal}
        showApplyModal={showApplyModal}
      />
    </div>
  );
};

export default JobBoardDetail;
