export const locations = [
  {
    id: 1,
    name: 'Brisbane'
  },
  {
    id: 2,
    name: 'Sydney'
  },
  {
    id: 3,
    name: 'Melbourne'
  },
  {
    id: 4,
    name: 'Perth'
  },
  {
    id: 5,
    name: 'Adelaide'
  }
]
