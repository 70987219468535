import React from 'react'

export default function Alert ({
  showAlert,
  setShowAlert
}: {
  showAlert: any;
  setShowAlert: (value: boolean) => void;
}) {
  if (showAlert) {
    return (
      <div
        className={[
          'py-[24px] px-[32px] absolute bg-primary rounded-[12px] flex items-center justify-between w-full max-w-[512px] transition-all',
          showAlert ? (showAlert.show ? 'show-down' : 'hidden') : 'hidden'
        ].join(' ')}
      >
        <div className="flex">
          <div
            className={[
              'w-[48px] h-[48px] bg-success-main rounded-full flex items-center justify-center mr-[16px]',
              showAlert.type === 'success'
                ? 'bg-success-main'
                : 'bg-danger-main'
            ].join(' ')}
          >
            {showAlert.type === 'success'
              ? (
                <img src="/assets/icons/ic-submitted.svg" alt="" />
              )
              : (
                <img src="/assets/icons/ic-submit-error.svg" alt="" />
              )}
          </div>
          <div>
            <p className="text-white text-[16px] font-semibold text-ambit-semibold">
              {showAlert.type === 'success'
                ? 'Submitted!'
                : 'Submission Failed'}
            </p>
            <p className="text-[14px] text-bodymedium text-[#96A0B5]">
              {showAlert.type === 'success'
                ? 'Document has been submitted!'
                : 'Document has not been submitted'}
            </p>
          </div>
        </div>
        <button onClick={() => setShowAlert(false)}>
          <img
            src="/assets/icons/ic-x.svg"
            style={{
              filter: 'brightness(0) invert(1)'
            }}
            alt=""
          />
        </button>
      </div>
    )
  }
  return null
}
