/* eslint-disable camelcase */
import clsx from "clsx";
import React from "react";
import teamData from "screens/About/components/Team/data";

export default function JobContact({
  data,
  isLoading,
}: {
  data: any;
  isLoading: boolean;
}) {
  console.log(data);
  const owner_detail = teamData.find((item) => {
    return item.name === data?.owners[0].name;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="max-w-[348px] phone:w-[100%] tablet:w-[283px] w-full rounded-[6px] border border-black rounded-6px pt-[40px] pb-[24px] bg-white phone:mt-[40px]"
      style={{ boxShadow: "-4px 4px 0px rgba(0,0,0,1)" }}
    >
      <div
        className="w-[216px] h-[216px] phone:w-[120px] phone:h-[120px] rounded-full border mx-auto overflow-hidden text-center flex items-start  justify-center mb-[16px]"
        style={{
          background:
            "linear-gradient(71.38deg, #FFEFE1 6.31%, #FFEFBE 90.95%)",
          borderColor: data.owners[0].name.includes("Toyne")
            ? "transparent"
            : "#000",
        }}
      >
        <img
          src={
            data.owners[0].avatar_original_url.includes("missing.png") &&
            data.owners[0].name.includes("Toyne")
              ? "/assets/images/person/toyne.svg"
              : data.owners[0].avatar_original_url
          }
          className="w-full phone:max-w-[183px]"
          alt=""
        />
      </div>
      <div className="text-center">
        <p className="text-h4 tablet:text-bodylarge  font-bold text-ambit-bold text-info-pressed">
          {!data.owners[0].name ? "-" : data.owners[0].name}
        </p>
        <p className="text-bodymedium font-light text-ambit-light text-secondary-950">
          {data.owners[0].name.includes("Jordan")
            ? "Director"
            : owner_detail?.owner_detail.role}
        </p>
      </div>
      <div className="px-[24px] mt-[24px]">
        <ul className="space-y-[8px]">
          <li className="flex items-center">
            <div className="w-[14px] h-[14px] bg-primary rounded-full flex items-center justify-center mr-[10px]">
              <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
            </div>
            <span className="text-bodymedium text-ambit-light text-secondary-900">
              {owner_detail?.owner_detail.category || "-"}
            </span>
          </li>
          <li className="flex items-center">
            <div className="w-[14px] h-[14px] bg-primary rounded-full flex items-center justify-center mr-[10px]">
              <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
            </div>
            <span className="text-bodymedium text-ambit-light text-secondary-900">
              {owner_detail?.owner_detail.recent_placement || "-"}
            </span>
          </li>
        </ul>
        <div className="h-[1px] bg-[#BABBBF] mt-[16px]"></div>
        <p
          className={clsx(
            "mt-[16px] text-bodymedium font-light text-ambit-light text-secondary-600",
            "line-clamp-2 md:line-clamp-3 xl:line-clamp-2"
          )}
        >
          {owner_detail?.owner_detail.description}
        </p>
        <a
          href={`mailto:${data.owners[0].email}`}
          className="block text-center border border-button tablet:text-bodymedium text-button rounded-[6px] mt-[24px] w-full py-[12px] font-semibold text-ambit-semibold"
        >
          Contact {data.owners[0].name}
        </a>
      </div>
    </div>
  );
}
