import React from 'react'
import { Reveal, Tween } from 'react-gsap'

const Title = () => {
  return (
    <Reveal>
      <Tween from={{ opacity: 0, transform: 'scale(0)' }}>
        <h2 className="phone:max-w-[327px] text-white text-h2 tablet:text-h3 phone:text-h3 phone:text-left text-ambit-bold font-bold text-center mb-[16px] phone:mb-[32px] 4k:text-[70px] 4k:leading-[100px] 4k:mb-[40px]">
          Connect Great Business with Great People
          <span className="text-info-main block phone:inline phone:ml-2">
            Because Without People a Business is Nothing.
          </span>
        </h2>
      </Tween>
    </Reveal>
  )
}

export default Title
