import React from "react";
import { Timeline, Tween } from "react-gsap";
import { useMediaQuery } from "react-responsive";

import ImageAnimation from "./ImageAnimation";

const Hero = () => {
  const handleStartHiring = () => {
    window.open("https://calendly.com/jordan-gko4", "_blank");
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <section className="relative overflow-hidden pb-[276px] tablet:pb-[240px] phone:pb-[200px] mt-[-102px] 4k:mt-[-208.8px] tablet:mt-[-114px] phone:mt-[-150px]">
      <img
        src="/assets/images/shapes/landing-hero-shape.svg"
        className="w-full absolute scale-[2] top-[-485px] largeDesktop:top-0 tablet:top-[20px] tablet:left-[100px] phone:top-[292px] phone:scale-[4] phone:left-[90px] left-0 right-0 bottom-0 z-10 tablet:scale-x-[2.3]"
        alt=""
      />
      <div className="relative z-20">
        <div className="text-center">
          <Timeline
            target={
              <>
                <p className="text-bodymedium tablet:text-bodysmall font-semibold mt-[206px] tablet:mt-[184px] mb-[24px] tablet:mb-[21px] phone:mb-[24px] text-secondary-600 4k:text-h3 4k:mt-[400px]">
                  120K professional talents
                </p>
                <h1 className="text-[94px] tablet:text-[72px] tablet:leading-[90px] tablet:max-w-[736px] leading-[108.1px] largeDesktop:text-[94px] largeDesktop:leading-[108.1px] phone:text-h3 font-bold text-secondary-950 max-w-[968px] tracking-[1px] mx-auto text-ambit-bold 4k:text-[130px] 4k:max-w-[1400px] 4k:leading-[160px] phone:max-w-[320px]">
                  {isMobile
                    ? "We help you source, match and hire sales and marketing talent faster"
                    : "We help you source, match and hire talent faster"}
                </h1>
                <p className="text-secondary-900 mx-5 md:mx-0 text-bodylarge tablet:text-bodymedium text-ambit-light mt-[24px] 4k:text-h2 4k:mt-[32px]">
                  #1 Sales and Marketing Recruitment Agency in Australia
                </p>
                <button
                  onClick={handleStartHiring}
                  className="mt-[42px] phone:mt-[40px] bg-button hover:bg-button-hover py-[20px] px-[65px] text-bodymedium rounded-[4px] text-white text-ambit-bold 4k:text-h2 4k:rounded-[10px] 4k:py-[24px] 4k:px-[100px] 4k:mt-[62px] relative z-50"
                >
                  Start Hiring
                </button>
                <p className="text-secondary-600 mt-[22px] 4k:mt-[42px] text-bodysmall 4k:text-h3">
                  🔥 &nbsp;Web3 database & Ai powered results
                </p>
              </>
            }
          >
            <Tween
              from={{ opacity: 0, transform: "translateY(-20px)" }}
              duration={0.4}
              target={0}
            />
            <Tween
              from={{ opacity: 0, transform: "translateY(-20px)" }}
              duration={0.35}
              target={1}
            />
            <Tween
              from={{ opacity: 0, transform: "translateY(-20px)" }}
              duration={0.3}
              target={2}
            />
            <Tween
              from={{ opacity: 0, transform: "translateY(-20px)" }}
              duration={0.275}
              target={3}
            />
            <Tween
              from={{ opacity: 0, transform: "translateY(-20px)" }}
              duration={0.25}
              target={4}
            />
          </Timeline>
        </div>

        <ImageAnimation />
      </div>
    </section>
  );
};

export default Hero;
