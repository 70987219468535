import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import InputSearch from './InputSearch'
import SelectLocation from './SelectLocation'
import { locations } from './data'

const JobSearchForm = () => {
  const search = useLocation().search
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState(new URLSearchParams(search).get('location') || '')
  const [query, setQuery] = useState(new URLSearchParams(search).get('query') || '')

  const handleChangeSearch = (e: any) => {
    setQuery(e.target.value)
  }
  const onSubmit = (e: any) => {
    e.preventDefault()
    const url = new URL(window.location.href)
    // url.searchParams has several function, we just use `set` function
    // to set a value, if you just want to append without replacing value
    // let use `append` function

    url.searchParams.set('query', `${query}`)
    url.searchParams.set('location', `${location}`)

    window.location.assign(url)
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex phone:block relative tablet:items-stretch tablet:w-full 4k:scale-[2] 4k:translate-y-[30px] 4k:translate-x-[560px]">
          <InputSearch query={query} handleChangeSearch={handleChangeSearch} />
          <SelectLocation location={location} locations={locations} setLocation={setLocation} />
          <div className="relative bg-white py-[16px] px-[24px] hidden xl:block phone:mt-[24px]">
            <button className="px-[32px] py-[14px] text-ambit-bold text-bodymedium font-bold text-white bg-button rounded-[6px]">
              Search job
            </button>
          </div>
        </div>
        <button className="py-[16px] w-full mt-[24px] block xl:hidden phone:mt-[24px] text-bodymedium text-ambit-bold text-white bg-button rounded-[6px]">
          Search Job
        </button>
      </form>
    </>
  )
}

export default JobSearchForm
