import clsx from 'clsx'
import React from 'react'

const FilterButton = ({
  isOpenFilter,
  onClick
}: {
  isOpenFilter: boolean;
  onClick():void;
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'flex',
        'justify-center',
        'gap-x-[6px]',
        'border border-[#0C0A1A]',
        'rounded-md',
        'w-full',
        'h-12',
        'text-base',
        'font-bold',
        'text-ambit-bold',
        'items-center',
        'transition-all duration-300',
        isOpenFilter ? 'bg-[#0C15A7] text-white' : 'bg-transparent text-[#0C0A1A]'
      )}
    >
      <IconFilter isOpenFilter={isOpenFilter}/>
      Filters
    </button>
  )
}

const IconFilter = ({ isOpenFilter } : {isOpenFilter: boolean;}) => {
  return (
    <svg
      width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 6.45825H2.5C2.15833 6.45825 1.875 6.17492 1.875 5.83325C1.875 5.49159 2.15833 5.20825 2.5 5.20825H17.5C17.8417 5.20825 18.125 5.49159 18.125 5.83325C18.125 6.17492 17.8417 6.45825 17.5 6.45825Z" fill={isOpenFilter ? 'white' : '#0C0A1A'}/>
      <path d="M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z" fill={isOpenFilter ? 'white' : '#0C0A1A'}/>
      <path d="M11.6668 14.7917H8.3335C7.99183 14.7917 7.7085 14.5084 7.7085 14.1667C7.7085 13.8251 7.99183 13.5417 8.3335 13.5417H11.6668C12.0085 13.5417 12.2918 13.8251 12.2918 14.1667C12.2918 14.5084 12.0085 14.7917 11.6668 14.7917Z" fill={isOpenFilter ? 'white' : '#0C0A1A'}/>
    </svg>
  )
}

export default FilterButton
