/* eslint-disable camelcase */
import clsx from 'clsx'
import { ArrowBtn } from 'components'
import { paginate } from 'utils'
import React, { useEffect, useState } from 'react'
import { Reveal, Timeline, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'
import { clients, clients_mobile, Client_Entity } from './data'

import ToogleMobile from './ToogleMobile'

const Clients = () => {
  const isDesktop = useMediaQuery({ minWidth: 1440 })
  const isTablet = useMediaQuery({ minWidth: 1024 })
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [toggleMore, setToggleMore] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const limit = isDesktop ? 3 : isTablet ? 4 : 2
  const clientsRawData = isMobile ? clients_mobile : clients
  const [clientData, setClientData] = useState<Array<Client_Entity>>(
    isDesktop
      ? paginate(clientsRawData, currentPage, 12)
      : isTablet
        ? paginate(clientsRawData, currentPage, 8)
        : paginate(clientsRawData, currentPage, 10)
  )

  const handleSeeMore = (type: string) => {
    if (type === 'more') {
      setClientData(clientsRawData)
      setToggleMore(true)
    } else {
      setClientData(paginate(clientsRawData, 1, 10))
      setToggleMore(false)
    }
  }

  useEffect(() => {
    setClientData(
      paginate(clientsRawData, currentPage, isDesktop ? 12 : isTablet ? 8 : 10)
    )
  }, [currentPage, isDesktop, isTablet, clientsRawData])
  console.log(currentPage)
  const handleArrowClick = (direction: string) => {
    if (direction === 'left') {
      if (currentPage > 1) setCurrentPage(currentPage - 1)
    } else {
      if (currentPage < limit) setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className="min-h-[321px]">
      <div className="relative z-30 phone:z-50 bg-white py-[64px] md:h-[321px] tablet:py-[48px] phone:py-[48px] phone:px-[23.5px] w-[90%] max-w-[1264px] tablet:max-w-[912px] phone:w-[100%] mx-auto mt-[-190px] tablet:mt-[-210px] 4k:max-w-[2700px] 4k:py-[100px]">
        <Reveal>
          <Timeline
            target={
              <p className="text-bodylarge phone:text-h3 font-bold text-ambit-bold mb-[40px] text-black text-center 4k:text-5xl 4k:mb-[80px]">
                TRUSTED BY
              </p>
            }
          >
            <Tween
              from={{ transform: 'translateY(-20px)', opacity: 0 }}
              to={{ transform: 'translateY(0px)', opacity: 1 }}
              duration={0.6}
            />
            <div className="client-wrapper px-[24px] flex justify-center items-center relative">
              {!isMobile && (
                <ArrowBtn
                  direction="left"
                  onClick={() => handleArrowClick('left')}
                  classes={clsx(
                    'absolute top-[10px] tablet:top-[12px] left-[20px]'
                  )}
                  iconClassName={currentPage > 1 ? 'fill-[#0C0A1A]' : 'fill-[#BABBBF]'}
                />
              )}

              <div
                className={[
                  'grid md:grid-cols-4 2xl:grid-cols-6 phone:grid-cols-2 gap-y-[41px] gap-x-[32px] phone:gap-x-[8px] phone:gap-y-[24px]  phone:mt-[0px] 4k:gap-y-[106px]'
                ].join(' ')}
              >
                {clientData.map((client: Client_Entity) => {
                  return (
                    <Tween
                      from={{ opacity: 0, transform: 'translateY(0px)' }}
                      duration={0.3}
                      key={client.id}
                    >
                      <div
                        className={
                          'w-[160px] client-logo 4k:w-[320px] text-center flex items-center justify-center h-[40px]'
                        }
                        id={client.name}
                      >
                        <img
                          src={`/assets/images/clients/${client.image}`}
                          className="w-full scale-[0.9]"
                          alt={client.name}
                        />
                      </div>
                    </Tween>
                  )
                })}
              </div>
              {!isMobile && (
                <ArrowBtn
                  direction="right"
                  onClick={() => handleArrowClick('right')}
                  classes="absolute top-[10px] tablet:top-[12px] right-[20px]"
                  iconClassName={currentPage < limit ? 'fill-[#0C0A1A]' : 'fill-[#BABBBF]'}
                />
              )}
            </div>
            <div className="text-center relative z-50">
              {isMobile && !toggleMore && (
                <ToogleMobile
                  onClick={() => handleSeeMore('more')}
                  label="See More"
                />
              )}
              {isMobile && toggleMore && (
                <ToogleMobile
                  onClick={() => handleSeeMore('less')}
                  label="See Less"
                />
              )}
            </div>
          </Timeline>
        </Reveal>
      </div>
    </div>
  )
}

export default Clients
