import React, { useEffect, useState } from 'react'

import {
  getCountingCategories,
  getCountingLocation
} from 'screens/JobBoard/infrastructure/api'

import Checkbox from './Checkbox'

export default function FilterCard ({
  selectedCategories,
  selectedLocations,
  handleSelectFilter
}: {
  selectedLocations: Array<number>;
  selectedCategories: Array<number>;
  handleSelectFilter: (id: number, filterType:string) => void;
}) {
  const [jobCategories, setJobCategories] = useState([])
  const [jobLocations, setJobLocations] = useState([])

  useEffect(() => {
    getCountingCategories().then((res: any) => setJobCategories(res))
    getCountingLocation().then((res: any) => setJobLocations(res))
  }, [])

  return (
    <div className="filter-card p-[32px] 4k:p-[64px] tablet:p-[24px] border border-secondary-950 rounded-[6px] 2xl:min-w-[352px] max-w-[352px] tablet:max-w-[284px] 4k:max-w-[550px] 4k:rounded-[10px] 4k:w-full phone:max-w-[100%] phone:hidden">
      <p className="text-bodylarge tablet:text-bodymedium text-ambit-semibold font-semibold text-secondary-950 mb-[16px] 4k:text-h2">
        Business sectors
      </p>
      {jobCategories.length > 0 &&
        // eslint-disable-next-line array-callback-return
        jobCategories.map((item: any, index) => {
          if (item) {
            return (
              <div
                key={index}
                className="flex items-center justify-between py-[12px] 4k:py-[24px]"
                onClick={() => handleSelectFilter(item.id, 'category')}
              >
                <div className="flex items-center">
                  <Checkbox
                    active={
                      selectedCategories.length > 0 &&
                      selectedCategories.includes(item.id)
                    }
                  />
                  <p className="ml-[16px] text-bodymedium tablet:text-bodysmall text-ambit-semibold font-semibold text-secondary-950 mr-[24px] tablet:mr-[13px] 4k:text-h3">
                    {item.name}
                  </p>
                </div>
                <p className="text-ambit-light tablet:text-bodysmall 4k:text-h3">
                  {item?.total_count ?? 0}
                </p>
              </div>
            )
          }
        })
      }

      <div className=" py-[24px] 4k:py-[54px]">
        <span className="h-[1px] border border-secondary-300 block"></span>
      </div>
      <p className="text-bodylarge tablet:text-bodymedium text-ambit-semibold font-semibold text-secondary-950 mb-[16px] 4k:text-h2">
        Job Locations
      </p>
      {jobLocations.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="flex items-center justify-between py-[12px] 4k:py-[24px]"
            onClick={() => handleSelectFilter(item.address, 'location')}
          >
            <div className="flex items-center">
              <Checkbox active={selectedLocations?.includes(item.address) ?? false} />
              <p className="ml-[16px] text-bodymedium tablet:text-bodysmall text-ambit-semibold font-semibold text-secondary-950  4k:text-h3">
                {item.address}
              </p>
            </div>
            <p className="text-ambit-light tablet:text-bodysmall  4k:text-h3">
              {item.count}
            </p>
          </div>
        )
      })}
    </div>
  )
}
