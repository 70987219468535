import axios from "axios";

const ApiClient = axios.create({
  baseURL: "https://loxo.co/api/blended-employment-1",
  withCredentials: true,
  auth: {
    username: "blended-employment-1_api",
    password: "6Yb!usGYid",
  },
});

export default ApiClient;
