import React from 'react'
import { Reveal, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'

const Contact = () => {
  const isTablet = useMediaQuery({ maxWidth: 1024 })
  const isDesktop = useMediaQuery({ maxWidth: 1440 })
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return (
    <section className="relative pt-[90px] phone:pt-[56px] z-20 4k:scale-[2.3] 4k:py-[300px]">
      <div className="max-w-[1120px] phone:max-w-[327px] tablet:max-w-[912px] w-[90%] mx-auto">
        <div className="flex items-start phone:flex-col">
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }}>
              <div className="space-y-[32px] tablet:space-y-[24px] phone:space-y-[32px] xl:max-w-[640px] phone:max-w-[full] 3xl:max-w-[800px] mr-[96px] phone:mr-0 tablet:max-w-[489px]">
                <h4 className="text-h2 tablet:text-h3 phone:text-h4 font-bold text-ambit-bold text-secondary-900 phone:mb-[16px]">
                  Bringing People Together.
                </h4>
                <div>
                  <p className="text-bodylarge tablet:text-bodymedium font-bold text-ambit-bold text-secondary-900 mb-[10px] largeDesktop:mb-[16px] phone:mb-[16px]">
                    Connecting Great Business with Great People Because Without
                    People a Business is Nothing.
                  </p>
                  <p className="text-bodymedium tablet:text-bodysmall text-ambit-light font-light text-secondary-800 tablet:max-w-[483px]">
                    For a result-driven recruitment service in Australia that is
                    tailored to your unique requirements, Blended Employment is
                    your one-stop solution. Where industry expertise meets a
                    team truly dedicated to the success of your business,
                    there’s no one better – For more information on our expert
                    sales and marketing recruitment in New Zealand and Australia
                  </p>
                </div>
                <div>
                  <p className="text-caption font-semibold text-ambit-semibold text-black">
                    Please contact our team today
                  </p>
                  <div className="mt-[16px] flex phone:flex-col items-center pb-[88px] tablet:pb-[80px] phone:pb-[40px] phone:space-y-[16px]">
                    <button
                      onClick={() => {
                        window.location.assign('tel:0736678895')
                      }}
                      className="bg-info-main text-white rounded-[6px] py-[16px] px-[24px] text-ambit-semibold mr-[24px] phone:mr-0 phone:w-full"
                    >
                      <img
                        src="/assets/icons/ic-call.svg"
                        className="mr-[6px] inline-block"
                      />
                      Call our Company
                    </button>
                    <button
                      onClick={() => {
                        window.location.assign(
                          'mailto:declan@blendedemployment.com.au'
                        )
                      }}
                      className="bg-button text-white rounded-[6px] py-[16px] px-[24px] text-ambit-semibold phone:w-full"
                    >
                      <img
                        src="/assets/icons/ic-dm.svg"
                        className="mr-[6px] inline-block"
                      />
                      Email to our Company
                    </button>
                  </div>
                </div>
              </div>
            </Tween>
            <Tween from={{ opacity: 0, transform: 'translateX(20px)' }}>
              <div className="w-[544px] tablet:w-[417px] tablet:h-[428px] phone:h-[100%] absolute phone:static phone:w-[360px] bottom-0 right-0 4k:relative ">
                <img
                  src={
                    isTablet
                      ? '/assets/images/industry-section-3-tablet.png'
                      : isDesktop
                        ? '/assets/images/industry-section-3.png'
                        : '/assets/images/industry-section-3.png'
                  }
                  style={{
                    boxShadow: isMobile
                      ? '8px -8px 0px rgba(0,0,0,1)'
                      : '16px -16px 0px rgba(0,0,0,1)'
                  }}
                  className="w-full rounded-[6px] 4k:scale-[2.1] 4k:translate-y-[150px] 4k:translate-x-[140px]"
                  alt=""
                />
              </div>
            </Tween>
          </Reveal>
        </div>
      </div>
    </section>
  )
}

export default Contact
