import clsx from 'clsx'
import React from 'react'

interface Props {
  query: string;
  handleChangeSearch(events:any): void;
}

const InputSearch = ({ query, handleChangeSearch } : Props) => {
  return (
    <div className="flex items-center relative bg-white tablet:w-full phone:mb-[20px] ">
      <input
        type="text"
        name="query"
        value={query ?? ''}
        onChange={handleChangeSearch}
        placeholder="Your dream job ..."
        className={clsx(
          'pl-[48px]',
          'py-[16px] phone:py-[22px]',
          'outline-none',
          'phone:border-0 border-r block border-secondary-300 placeholder:text-secondary-600 text-black text-bodysmall text-ambit-light'
          // 'my-[16px] tablet:my-[9px]'
        )}
      />
      <div className="absolute left-4 top-0 h-full flex items-center justify-center">
        <img src="/assets/icons/ic-search.svg" alt="" />
      </div>
    </div>
  )
}

export default InputSearch
