import React from 'react'
import Helmet from 'react-helmet'
import { Header, Footer, FixedHeader } from 'components'
import Clients from './components/Clients'
import Contact from './components/Contact'
import Features from './components/Features'
import Hero from './components/Hero'
import IndustrySpecialization from './components/IndustrySpecialization'

const Home: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Blended Employment | Recruitment Specialist - Sales And Marketing Recruitment</title>
        <meta name='description' content='Blended Employment is your number one recruitment expert specialist in Australia. We offer sales and marketing recruitment for all levels and industries.'/>
        <meta name='keywords' content='recruitment specialist, sales and marketing recruitment'/>
        <link rel='canonical' href='https://blendedemployment.com.au/' />
      </Helmet>

      <Header classes="mt-[32px]" />
      <Hero />
      <Clients />
      <Features />
      <IndustrySpecialization />
      <Contact />
      <Footer />
      <FixedHeader />
    </div>
  )
}

export default Home
