import React, { useRef } from 'react'
import { Reveal, Tween } from 'react-gsap'

import SectorCard from './SectorCard'

import CardAnimation from './CardAnimation'

interface Props {
  handleStartHiring():void;
}

const CommercialIndustries = ({ handleStartHiring }:Props) => {
  const cardRef = useRef<any>([])
  CardAnimation(cardRef)

  return (
    <div className="relative z-20">
      {/* Technology & Digital */}
      <div className="flex phone:flex-col justify-between items-center mt-[80px] tablet:mt-[64px] phone:mt-[48px]">
        <Reveal>
          <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }}>
            <div>
              <h3 className="text-h3 tablet:text-h4 text-secondary-950 font-bold text-ambit-bold mb-[24px] phone:mb-[8px]">
                    Commercial & Industrial
              </h3>
              <p className="text-ambit-light font-light text-secondary-900 tablet:text-bodysmall phone:text-bodysmall phone:mb-[24px]">
                    Our expertise expands <span className="font-bold"> Commercial and Industrial </span>
                    segment and roles.
              </p>
            </div>
          </Tween>
          <Tween from={{ opacity: 0, transform: 'translateX(20px)' }}>
            <button
              onClick={handleStartHiring}
              className="text-bodymedium text-ambit-semibold tablet-text-ambit-bold font-semibold py-[16px] tablet:py-[12px] tablet:px-[40px] px-[24px] bg-button rounded-[6px] phone:w-full text-white tablet:text-ambit-bold"
            >
                  Start Hiring
            </button>
          </Tween>
        </Reveal>
      </div>

      <div className="mt-[56px] tablet:mt-[40px] phone:mt-[24px] grid grid-cols-4 phone:grid-cols-2 tablet:grid-cols-3  gap-x-[32px] gap-y-[24px]  phone:gap-x-[16px] phone:gap-y-[16px]">
        <SectorCard refContainer={(element:any) => { cardRef.current[0] = element }} icon="ic-home" name="Sales Engineering Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[1] = element }} icon="ic-home" name="Sales Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[2] = element }} icon="ic-home" name="Applications Engineer" />
        <SectorCard refContainer={(element:any) => { cardRef.current[3] = element }} icon="ic-home" name="Director, Sales Engineering" />
        <SectorCard refContainer={(element:any) => { cardRef.current[4] = element }} icon="ic-home" name="Solutions Consultant" />
        <SectorCard refContainer={(element:any) => { cardRef.current[5] = element }} icon="ic-home" name="Solutions Engineer" />
        <SectorCard refContainer={(element:any) => { cardRef.current[6] = element }} icon="ic-home" name="Field Sales Representative" />
        <SectorCard refContainer={(element:any) => { cardRef.current[7] = element }} icon="ic-home" name="Technical Account Executive" />
        <SectorCard refContainer={(element:any) => { cardRef.current[8] = element }} icon="ic-home" name="Field Service Engineer" />
        <SectorCard refContainer={(element:any) => { cardRef.current[9] = element }} icon="ic-home" name="Pre-Sales Engineer" />
        <SectorCard refContainer={(element:any) => { cardRef.current[10] = element }} icon="ic-home" name="Pre-Sales Specialist" />
        <SectorCard refContainer={(element:any) => { cardRef.current[11] = element }} icon="ic-home" name="Technical Sales Consultant" />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[12] = element }}
          icon="ic-home"
          name="Technical Sales Representative"
        />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[13] = element }}
          icon="ic-home"
          name="Professional Services Manager"
        />
        <SectorCard refContainer={(element:any) => { cardRef.current[14] = element }} icon="ic-home" name="Sales Engineer" />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[15] = element }}
          icon="ic-home"
          name="Technical Business Development Manager"
        />
        <SectorCard refContainer={(element:any) => { cardRef.current[16] = element }} icon="ic-home" name="Territory Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[17] = element }} icon="ic-home" name="External Sales" />
        <SectorCard refContainer={(element:any) => { cardRef.current[18] = element }} icon="ic-home" name="Sales Representative" />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[19] = element }}
          icon="ic-home"
          name="Solutions Engineering Manager"
        />
        <SectorCard refContainer={(element:any) => { cardRef.current[20] = element }} icon="ic-home" name="Technical Account Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[21] = element }} icon="ic-home" name="Product Specialist" />
        <SectorCard refContainer={(element:any) => { cardRef.current[22] = element }} icon="ic-home" name="Senior Sales Engineer" />
        <SectorCard refContainer={(element:any) => { cardRef.current[23] = element }} icon="ic-home" name="Area Sales Manager" />
      </div>

    </div>
  )
}

export default CommercialIndustries
