/* eslint-disable no-unused-vars */
import React from 'react'
import { Spinner } from '@react-pdf-viewer/core'
import SkeletonCard from 'components/SkeletonCard'
import dayjs from 'lib/Dayjs'
import Pagination from '../Pagination'
import JobCard from './JobCard'
import FilterCardMobile from '../FilterCardMobile'

const RecentJobs = ({
  selectedLocations,
  selectedCategories,
  handleSelectFilter,
  isLoading,
  data,
  handlePagination,
  pagination
}: {
  selectedLocations: Array<number>;
  selectedCategories: Array<number>;
  handleSelectFilter: (id: number, filterType: string) => void;
  isLoading: boolean;
  handlePagination: any;
  data: Array<any>;
  pagination: {
    current_page: number;
    per_page: number;
    total_count: number;
    total_pages: number;
  };
}) => {
  const isSameDate = (dateToday: Date, jobDate: string) => dateToday.toISOString().slice(0, 10) === jobDate.slice(0, 10)

  const lenghtNewJobToday = data.filter((item) => {
    return isSameDate(new Date(), item.created_at)
  }).length

  return (
    <div className="w-full max-w-[736px] tablet:max-w-[596px] phone:min-w-[0px] phone:max-w-[327px] 4k:max-w-[72%]">
      <h2 className="text-h2 4k:text-[70px] tablet:text-h3 text-ambit-bold mb-[16px] 4k:mb-[32px] text-secondary-950">
        Recent New Jobs {''}
      </h2>
      <p className="text-ambit-light text-bodymedium text-secondary-900 mb-[24px] 4k:text-h2">
        {lenghtNewJobToday} new jobs posted today
      </p>

      <div className="h-[1px] bg-secondary-300 phone:mb-8 mb-[40px]"></div>

      <FilterCardMobile
        selectedLocations={selectedLocations}
        selectedCategories={selectedCategories}
        handleSelectFilter={handleSelectFilter}
      />

      {isLoading && (
        <div className="job-cards grid grid-cols-2 phone:grid-cols-1 gap-x-[32px] phone:gap-y-[32px] gap-y-[40px]">
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </div>
      )}
      <div className="job-cards grid grid-cols-2 phone:grid-cols-1 gap-x-[32px] phone:gap-y-[32px] gap-y-[40px]">
        {!isLoading &&
          data &&
          data?.map?.((item, index) => {
            return (
              <JobCard
                key={item.id}
                id={item.id}
                title={item.title}
                specs={[
                  item?.categories.length > 0
                    ? item?.categories[0].name
                    : 'Unknown Categories',
                  item?.salary
                    ? 'Salary ' + item?.salary
                    : 'Salary not mentioned',
                  item?.job_type?.name
                ]}
                description={
                  'Are you a passionate SERVICE TECHNICIAN in the water treatment space?!'
                }
                date={dayjs(item.created_at).format('Do MMM, YYYY')}
                city={item?.address}
                status={item?.status?.name === 'Active'}
              />
            )
          })}

      </div>
      <Pagination
        handlePagination={handlePagination}
        currentPage={pagination?.current_page}
        totalData={pagination?.total_count}
        totalPage={pagination?.total_pages}
      />
    </div>
  )
}

export default RecentJobs
