import React from 'react'

const PaginationItem = ({
  handlePagination,
  number,
  isActive
}: {
  handlePagination: any;
  number: number;
  isActive: boolean;
}) => {
  return (
    <button
      onClick={handlePagination}
      className={[
        'text-xs md:text-base tablet:text-[12px] py-[8px] phone:px-3 px-[15px] border border-secondary-300 rounded-[6px] text-caption-light',
        isActive
          ? 'bg-button border-button text-white'
          : 'border-secondary-300 text-secondary-600'
      ].join(' ')}
    >
      {number}
    </button>
  )
}

export default PaginationItem
