/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputField from './InputField'
import { postApplyJob } from 'screens/JobBoard/infrastructure/api'
import Alert from './Alert'

export const ErrorComp = ({
  error,
  touched
}: {
  error?: string;
  touched?: boolean;
}) => {
  return error && touched ? <p className="text-danger-main">{error}</p> : null
}

const ApplyModal = ({
  showApplyModal,
  setShowApplyModal,
  job_id
}: {
  job_id: number;
  showApplyModal: boolean;
  setShowApplyModal: (value: boolean) => void;
}) => {
  const [showAlert, setShowAlert] = useState<any>(null)
  const uploadFile: any = useRef()
  const [uploadedBlobUrl, setUploadedBlobUrl] = useState<any>(null)
  const [uploadedFile, setUploadedFile] = useState<any>(null)
  const [errorFile, setErrorFile] = useState('')

  const uploadFileHandler = () => {
    uploadFile.current.click()
  }

  useEffect(() => {
    setTimeout(() => {
      if (showAlert) setShowAlert(null)
    }, 3000)
  }, [showAlert])

  const handleUploadFile = (e: any) => {
    const file = e.target.files[0]
    const blobURL = URL.createObjectURL(file)
    setUploadedFile(file)
    setUploadedBlobUrl(blobURL)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone: '',
      linkedin: ''
    },
    onSubmit: async (values, action) => {
      action.setSubmitting(true)
      const formData = new FormData()
      formData.append('email', values.email)
      formData.append('name', values.name)
      formData.append('phone', values.phone)
      formData.append('linkedin', values.linkedin)
      formData.append('resume', uploadedFile)

      try {
        const res = await postApplyJob(formData, job_id)
        console.log(res)
        if (res.data.ok) {
          // show success alert
          setShowAlert({
            type: 'success',
            show: true
          })
        } else {
          // show error alert
          setShowAlert({
            type: 'error',
            show: true
          })
        }
        formik.setSubmitting(false)
      } catch (error) {
        setShowAlert({
          type: 'error',
          show: true
        })
      }
      formik.setValues({
        email: '',
        name: '',
        phone: '',
        linkedin: ''
      })
      formik.setTouched({
        email: false,
        name: false,
        phone: false,
        linkedin: false
      })
      setUploadedFile(null)
      setUploadedBlobUrl(null)
    },
    validationSchema: Yup.object({
      email: Yup.string().required().email(),
      name: Yup.string().required(),
      phone: Yup.string().required(),
      linkedin: Yup.string().required().url()
    })
  })

  return (
    <>
      <div
        className={[
          'fixed w-full bg-black/50 h-full inset-0 flex justify-center phone:items-start phone:py-[40px] items-start py-[40px]  4k:scale-[2] overflow-y-scroll',
          'overflow-x-hidden',
          showApplyModal ? 'flex' : 'hidden'
        ].join(' ')}
        style={{ zIndex: 9999 }}
      >
        <div
          className={[
            'apply-modal bg-white w-full phone:max-w-[335px] max-w-[480px] rounded-[20px]  flex flex-col  ',
            showApplyModal ? 'fade-bottom' : null
          ].join(' ')}
        >
          <Alert showAlert={showAlert} setShowAlert={setShowAlert} />

          <form onSubmit={formik.handleSubmit}>
            <div className="header p-[24px]">
              <h3 className="text-h4 phone:text-h5 font-bold text-secondary-950 flex items-center justify-between">
                <span> Apply Job</span>
                <button onClick={() => setShowApplyModal(false)}>
                  <img src="/assets/icons/ic-x.svg" alt="" />
                </button>
              </h3>
              <p className="text-[#6B7C94] phone:text-[13px] text-sm">
                Please complete the form below for a position with us
              </p>
            </div>
            <div className="h-[1px] bg-[#E2E8F0]"></div>
            <div className="p-[24px] ">
              <div className="form-group mb-[14px]">
                <label
                  htmlFor="email"
                  className="font-medium text-[#334155] text-xs mb-[8px]"
                >
                  Email <span className="text-danger-main">*</span>
                </label>
                <InputField
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Email Address"
                  error={formik.errors.email}
                  value={formik.values.email}
                />
                <ErrorComp
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
              </div>
              <div className="form-group mb-[14px]">
                <label
                  htmlFor="name"
                  className="font-medium text-[#334155] text-xs mb-[8px]"
                >
                  Name <span className="text-danger-main">*</span>
                </label>
                <InputField
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Full name"
                  error={formik.errors.name}
                  value={formik.values.name}
                />
                <ErrorComp
                  error={formik.errors.name}
                  touched={formik.touched.name}
                />
              </div>
              <div className="form-group mb-[14px]">
                <label
                  htmlFor="phone"
                  className="font-medium text-[#334155] text-xs mb-[8px]"
                >
                  Phone <span className="text-danger-main">*</span>
                </label>
                <div className="relative">
                  <div className="flex absolute left-[20px] top-[18px] border-r border-[#E2E8F0]">
                    <img
                      src="/assets/icons/ic-aus.svg"
                      className="mr-[12px]"
                      alt=""
                    />
                  </div>
                  <InputField
                    name="phone"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Phone number"
                    error={formik.errors.phone}
                    value={formik.values.phone}
                    className="pl-[62px]"
                  />
                  <ErrorComp
                    error={formik.errors.phone}
                    touched={formik.touched.phone}
                  />
                </div>
              </div>
              <div className="form-group mb-[14px]">
                <label
                  htmlFor="name"
                  className="font-medium text-[#334155] text-xs mb-[8px]"
                >
                  LinkedIn <span className="text-danger-main">*</span>
                </label>
                <InputField
                  name="linkedin"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="LinkedIn profile URL"
                  error={formik.errors.linkedin}
                  value={formik.values.linkedin}
                />
                <ErrorComp
                  error={formik.errors.linkedin}
                  touched={formik.touched.linkedin}
                />
              </div>
              <div className="resume-upload">
                <label
                  htmlFor="resume"
                  className="font-medium text-[#334155] text-xs mb-[8px]"
                >
                  Resume <span className="text-danger-main">*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleUploadFile}
                  ref={uploadFile}
                  className="opacity-0 absolute top-[-999999999px]"
                />
                <div
                  className={[
                    'rounded-[16px] h-[240px]  bg-[#F7F9FB] mt-[8px] outline-[#E2E8F0] cursor-pointer overflow-x-hidden',
                    !uploadedBlobUrl && 'flex items-center justify-center'
                  ].join(' ')}
                  onClick={uploadFileHandler}
                  style={{
                    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23E7E8EBFF' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")"
                  }}
                >
                  {!uploadedBlobUrl
                    ? (
                      <div className="text-center">
                        <img
                          src="/assets/icons/ic-doc-apply.svg"
                          className="mx-auto"
                          alt=""
                        />
                        <p className="text-[#6B7C94] font-bold mt-[19px] mb-[4px] text-xs">
                          Upload your resume
                        </p>
                        <p className="text-[#94A3B8] text-xs">
                          Drop your file or click{' '}
                          <span className="text-[#1a49ba] font-bold text-xs">here</span>{' '}
                          to browse file
                        </p>
                      </div>
                    )
                    : (
                      <div className="w-full">
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.js">
                          <div className="w-full h-full">
                            <Viewer fileUrl={uploadedBlobUrl} />
                          </div>
                        </Worker>
                      </div>
                    )}
                  {errorFile && (
                    <p className="text-danger-main">
                      You must upload your resume
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[1px] bg-[#E2E8F0]"></div>
            <div className="p-[24px]">
              <button
                className="py-[13px] text-center block w-full rounded-full bg-button text-white text-ambit-semibold disabled:bg-button-disabled"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ApplyModal
