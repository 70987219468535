/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import Lottie from 'react-lottie'
import * as landingAnimation from 'assets/animations/landing-animation-recruiter.json'
import HowItWork from './HowItWork'
import { Reveal, Timeline, Tween } from 'react-gsap'
import LandingShape from 'screens/Home/LandingShape'

import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import Title from './Title'
import { Hired, InProgress, Interview, Sourced } from './HowToBringPeople'
import clsx from 'clsx'

gsap.registerPlugin(ScrollTrigger)

const Features = () => {
  // const ref = useRef<any>(null);
  const isFullHDDesktop = useMediaQuery({ maxWidth: 1920 })
  const isDesktop = useMediaQuery({ maxWidth: 1440 })
  const is4k = useMediaQuery({ minWidth: 3840 })
  const isTablet = useMediaQuery({ maxWidth: 1024 })
  const isMobile = useMediaQuery({ maxWidth: 768 })

  useEffect(() => {
    gsap.to(document.querySelector('#landing-path'), {
      strokeDashoffset: 0,
      ease: 'none',
      duration: 1,
      scrollTrigger: {
        trigger: document.querySelector('#trigger-shape-curved-line')
      }
    })

    gsap.to(document.querySelector('#landing-line-long'), {
      strokeDashoffset: 0,
      ease: 'none',
      duration: 10,
      scrollTrigger: {
        trigger: document.querySelector('#trigger-scroll-line'),
        scrub: true,
        start: isMobile ? '-150px bottom' : 'top 600px',
        end: is4k ? 'bottom 200px' : isMobile ? 'bottom+=300px top' : 'bottom 400px'
      }
    })
    gsap.to(document.querySelector('#landing-line-short'), {
      strokeDashoffset: 0,
      ease: 'none',
      duration: 10,
      scrollTrigger: {
        trigger: document.querySelector('#trigger-landing-line-short'),
        scrub: true,
        start: '-100px bottom',
        end: 'bottom 600px'
      }
    })
    gsap.to(document.querySelector('#landing-curved-end'), {
      strokeDashoffset: 0,
      ease: 'none',
      duration: 2,
      scrollTrigger: {
        trigger: document.querySelector('#trigger-landing-line-short'),
        start: '+=400px center',
        end: 'bottom center',
        scrub: 1
      }
    })
    gsap.to(document.querySelector('#landing-curved-circle-end'), {
      fillOpacity: 1,
      scrollTrigger: {
        trigger: document.querySelector('#trigger-landing-line-short'),
        start: '+=400px center',
        end: 'bottom center',
        scrub: 1
      }
    })
    gsap.to(document.querySelector('#landing-straight-mobile'), {
      strokeDashoffset: 0,
      scrollTrigger: {
        trigger: document.querySelector('#trigger-shape-curved-line'),
        start: 'top center',
        end: 'bottom center',
        scrub: 1
      }
    })
  }, [])

  return (
    <section className="bg-primary relative phone:z-20 mt-[-170px] 4k:mt-[-320px] overflow-hidden">
      <div>
        <img
          src="/assets/images/shapes/landing-shape-2.svg"
          className="w-[6539px] absolute phone:hidden"
          alt=""
        />
        <img
          src="/assets/images/landing-bg-app.png"
          className={clsx(
            'absolute scale-[1.4] tablet:scale-[1.87] phone:hidden',
            'top-[568px] lg:top-[685px] xl:top-[600px]',
            'right-[-1038px] tablet:right-[-1039px] 3xl:right-[-1285px]'
          )}
          alt=""
        />
        <img
          src="/assets/images/shapes/landing-shape-3.svg"
          className="w-[2738.4px] absolute tablet:top-[588px] phone:top-[1702px] phone:left-[-40px] phone:scale-[1.2] 3xl:scale-[2]"
          alt=""
        />
        <img
          src="/assets/images/shapes/landing-shape-4.svg"
          className="w-[4029.68px] absolute bottom-0"
          alt=""
        />
      </div>
      <div className="max-w-[1122px] 4k:w-[100%] 4k:max-w-[2700px] w-[90%] tablet:max-w-[912px] mx-auto relative z-20">
        <div
          className="pt-[214px] tablet:pt-[223px] phone:pt-[212px] 4k:pt-[400px]"
          id="trigger-shape-curved-line"
        >
          <Title />

          {/* <img
            src={
              isMobile
                ? "/assets/images/shapes/landing-shape-1-mobile.svg"
                : "/assets/images/shapes/shape-1.svg"
            }
            className="block ml-[64px] phone:ml-[3px] tablet:max-w-[47%] 4k:w-[1300px]"
            alt=""
          /> */}
          <div className="block ml-[64px] phone:ml-[3px] tablet:max-w-[47%] 2xl:max-w-[43%] 4k:max-w-[40%] 4k:ml-[200px]">
            <LandingShape />
          </div>
          <Reveal>
            <Timeline
              target={
                <>
                  <p className="text-[23px] text-ambit-bold text-white mt-[32px] 4k:text-h1">
                    How to Bring The Right People Together
                  </p>
                  <p className="text-bodymedium pr-5 text-ambit-light text-white opacity-[80%] mt-[16px] max-w-[737px] 4k:mt-[32px] 4k:text-h3 4k:max-w-[1400px] ">
                    {isTablet
                      ? 'Blended Employment combines the global reach and data-driven matching to deliver you amazing design candidates in less time and a fraction of the cost.'
                      : `Blended Employment combines our years of experience and data-driven
              matching to ensure we build you a successful team.`}
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                target={0}
                duration={0.6}
              />
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                target={1}
                duration={0.6}
              />
            </Timeline>
          </Reveal>
        </div>
        {/* line  */}
        <div className="absolute top-[613px]  left-[60px] 4k:left-[198px] 4k:top-[1150px] tablet:top-[608px] phone:left-[12px] phone:top-[798px]">
          {/* <div
            className="w-[2px] h-[3170px] tablet:h-[2915px] phone:h-[2262px] landing-line-custom"
            style={{ background: "rgba(255, 255, 255, 0.56)" }}
          ></div> */}
          <svg
            width={40}
            className="h-[3170px] tablet:h-[2915px] phone:h-[2323px] 4k:h-[6465px]  "
          >
            <circle
              cx="10"
              cy={is4k ? 10 : '6'}
              r={is4k ? 10 : isTablet ? 5 : 6}
              fill="rgb(255, 255, 255,0.80)"
              strokeWidth="3"
            />
            <line
              x1={10}
              y1={12}
              x2={10}
              id="landing-line-long"
              y2={is4k ? 6500 : isMobile ? 2303 : isTablet ? 2900 : 3155}
              style={{
                stroke: 'rgb(255, 255, 255,0.56)',
                strokeWidth: 3,
                strokeDashoffset: is4k ? 8000 : 3170.06298828125,
                strokeDasharray: is4k
                  ? '8000 8000'
                  : '3170.06298828125 3170.06298828125'
              }}
            />
            <circle
              cx="10"
              cy={is4k ? 6455 : isMobile ? 2305 : isTablet ? 2903 : 3160}
              r={is4k ? 10 : isTablet ? 5 : 6}
              fill="rgb(255, 255, 255,0.80)"
              strokeWidth="3"
            />
          </svg>
        </div>
        {/* line end */}
        <div className="relative z-2">
          <div id="trigger-scroll-line">
            <Sourced />
            <InProgress />
            <Interview />
            <Hired />
          </div>
          <div className="mt-[112px] tablet:mt-[108px] mb-[88px]">
            <Reveal>
              <Timeline
                target={
                  <p className="pr-5 font-bold text-white md:pr-0 text-h4 text-ambit-bold 4k:text-h1">
                    Why We’re Better at Bringing People Together
                  </p>
                }
              >
                <Tween
                  from={{ opacity: 0, transform: 'translateY(20px)' }}
                  duration={0.6}
                />
              </Timeline>
              <div className="mt-[24px] 4k:mt-[52px] grid grid-cols-3 phone:grid-cols-1 gap-x-[24px] gap-y-[20px] 4k:gap-y-[30px]">
                <Timeline
                  target={
                    <>
                      <div className="flex items-center w-[357.33px] 4k:w-[650px] tablet:w-[283px]">
                        <img
                          src="/assets/icons/ic-check.svg"
                          className="mr-[8px] tablet:w-[20px] 4k:w-[40px] 4k:mr-[16px]"
                          alt=""
                        />
                        <div>
                          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] 4k:text-h2">
                            Sales & Marketing is our niche
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center w-[357.33px] 4k:w-[650px] tablet:w-[283px]">
                        <img
                          src="/assets/icons/ic-check.svg"
                          className="mr-[8px] tablet:w-[20px] 4k:w-[40px] 4k:mr-[16px]"
                          alt=""
                        />
                        <div>
                          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] 4k:text-h2">
                            We provide authentic solutions
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center w-[357.33px] tablet:w-[283px] 4k:w-[650px]">
                        <img
                          src="/assets/icons/ic-check.svg"
                          className="mr-[8px]  tablet:w-[20px] 4k:w-[40px] 4k:mr-[16px]"
                          alt=""
                        />
                        <div>
                          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] 4k:text-h2">
                            We follow a proven process
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center w-[357.33px] tablet:w-[283px] 4k:w-[650px]">
                        <img
                          src="/assets/icons/ic-check.svg"
                          className="mr-[8px]  tablet:w-[20px] 4k:w-[40px] 4k:mr-[16px]"
                          alt=""
                        />
                        <div>
                          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] 4k:text-h2">
                            Mid-level to C-Suite roles
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center w-[357.33px] tablet:w-[283px] 4k:w-[650px]">
                        <img
                          src="/assets/icons/ic-check.svg"
                          className="mr-[8px]  tablet:w-[20px] 4k:w-[40px] 4k:mr-[16px]"
                          alt=""
                        />
                        <div>
                          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] 4k:text-h2">
                            Experience with scaling teams
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center w-[357.33px] tablet:w-[283px] 4k:w-[800px]">
                        <img
                          src="/assets/icons/ic-check.svg"
                          className="mr-[8px]  tablet:w-[20px] 4k:w-[40px] 4k:mr-[16px]"
                          alt=""
                        />
                        <div>
                          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] 4k:text-h2">
                            AI Powered database built on Web3
                          </p>
                        </div>
                      </div>
                    </>
                  }
                >
                  <Tween from={{ opacity: 0 }} target={0} duration={0.1} />
                  <Tween from={{ opacity: 0 }} target={1} duration={0.15} />
                  <Tween from={{ opacity: 0 }} target={2} duration={0.20} />
                  <Tween from={{ opacity: 0 }} target={3} duration={0.25} />
                  <Tween from={{ opacity: 0 }} target={4} duration={0.30} />
                  <Tween from={{ opacity: 0 }} target={5} duration={0.35} />
                </Timeline>
              </div>
            </Reveal>
          </div>
          <div className="absolute top-[3416px] left-[60px] 4k:left-[78px] 4k:top-[1150px] tablet:top-[3156px] phone:left-[12px] phone:top-[847px] 4k:top-[6933px] 4k:left-[198px]">
            {/* <div
            className="w-[2px] h-[3170px] tablet:h-[2915px] phone:h-[2262px] landing-line-custom"
            style={{ background: "rgba(255, 255, 255, 0.56)" }}
          ></div> */}

            {/* <div
              className="w-[2px] h-[200px] tablet:h-[200px] phone:h-[200px] landing-line-custom"
              style={{ background: "rgba(255, 255, 255, 0.56)" }}
            ></div> */}
            <svg
              width={is4k ? 140 : 40}
              className="h-[200px] tablet:h-[200px] phone:h-[200px]"
            >
              <circle
                cx="10"
                cy={is4k ? 10 : '6'}
                r={is4k ? 10 : isTablet ? 5 : 6}
                fill="rgb(255, 255, 255,0.80)"
                strokeWidth="3"
              />
              <line
                x1={10}
                y1={12}
                x2={10}
                id="landing-line-short"
                y2={200}
                style={{
                  stroke: 'rgb(255, 255, 255,0.56)',
                  strokeWidth: 3,
                  strokeDashoffset: 188,
                  strokeDasharray: '188 188'
                }}
              />
              <circle
                cx="10"
                cy={isMobile ? 2262 : isTablet ? 2903 : 3160}
                r={isTablet ? 5 : 6}
                fill="rgb(255, 255, 255,0.80)"
                strokeWidth="3"
              />
            </svg>
          </div>
          <Reveal threshold={0.35}>
            <Tween from={{ opacity: 0 }}>
              <div
                className="w-full mt-[80px] 4k:mt-[130px] phone:mt-[90px] relative z-40 tablet:max-w-[832px]"
                id="trigger-landing-line-short"
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: landingAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                />
              </div>
            </Tween>
          </Reveal>
          {/* <img
            src="/assets/images/landing-animation-1.png"
            alt=""
            className="w-full mt-[80px] phone:mt-[90px] relative z-40 tablet:max-w-[832px]"
          /> */}
          <div className="relative ml-[72.5px] tablet:max-w-[47%] mb-[-74.5px] tablet:w-[379px] 4k:left-[198px] 4k:w-[1320px] phone:hidden">
            <svg
              width={is4k ? '100%' : isMobile ? 0 : isTablet ? '100%' : 494}
              height={is4k ? 300 : isTablet ? '152px' : 190}
              viewBox="0 0 494 190"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="landing-curved-end"
                d="M1.00003 0C1.00003 0 0.984458 30.9003 1.61891 50.836C1.7458 54.8232 2.84858 57.6197 7.96076 61.3022C15.5714 66.7846 28.2736 65.7878 28.2736 65.7878H463.35C463.35 65.7878 476.94 64.618 483.009 70.7717C487.712 75.5408 488.449 76.9743 488.719 81.2379C489.351 91.2058 488.719 180 488.719 180"
                stroke="white"
                strokeWidth={2}
                strokeOpacity="0.56"
                style={{
                  strokeDasharray: '1100 1100',
                  strokeDashoffset: 1100
                }}
              />
              <circle
                id="landing-curved-circle-end"
                cx="489"
                cy="182"
                r="6"
                fill="white"
                fillOpacity="0"
              />
            </svg>
          </div>
          {/* </div>
          <img
            className="relative ml-[72.5px] mb-[-74.5px] tablet:w-[379px] phone:hidden"
            src={
              isTablet
                ? "/assets/images/shapes/shape-3-tablet.svg"
                : isDesktop || isFullHDDesktop
                ? "/assets/images/shapes/shape-3.svg"
                : "/assets/images/shapes/shape-tablet.svg"
            }
            alt=""
          /> */}
          <HowItWork />
        </div>
      </div>
    </section>
  )
}

export default Features
