import React from 'react'

export default function InputField ({ className, ...props }: any) {
  return (
    <input
      type="text"
      className={[
        'py-[14px] px-[20px] w-full bg-[#F7F9FB] rounded-[16px] placeholder:text-[#94A3B8] text-secondary-950 outline-[#EBF1FC]',
        className
      ].join(' ')}
      {...props}
    />
  )
}
