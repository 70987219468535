import { Link } from 'react-router-dom'
import React from 'react'

const JobStatus = ({ active }: { active: boolean }) => {
  return (
    <span
      style={{
        background: active
          ? 'rgba(3, 208, 176, 0.05)'
          : 'rgba(255, 90, 80, 0.05)'
      }}
      className={[
        'text-bodysmall 4k:text-h4 font-semibold text-ambit-semibold block py-[6px] px-[12px] rounded-[40px] ',
        active ? 'text-success-main' : 'text-danger-main'
      ].join(' ')}
    >
      {active ? 'Open' : 'Closed'}
    </span>
  )
}

const JobCard = ({
  title,
  specs,
  description,
  status,
  date,
  city,
  id
}: {
  title: string;
  specs: Array<string>;
  description: string;
  status: boolean;
  date: string;
  city: string;
  id: number;
}) => {
  return (
    <div className="rounded-[6px] 4k:rounded-[10px] border border-black 4k:p-[30px] p-[20px] flex flex-col space-y-[12px] 4k:space-y-[32px] justify-between">
      <div className="card-header flex items-center phone:items-start justify-between">
        <p className="text-bodylarge tablet:text-bodymedium font-bold text-ambit-bold text-secondary-950 tablet:max-w-[158px] 4k:text-h2">
          {title}
        </p>
        <JobStatus active={status} />
      </div>
      <div className="card-info flex r">
        <div className="flex mr-[26px] 4k:items-center ">
          <img
            src="/assets/icons/ic-calendar.svg"
            className="mr-[10px] 4k:w-[40px]"
            alt=""
          />
          <p className="text-bodysmall tablet:text-bodysmall text-ambit-light text-secondary-600 4k:text-h4">
            {date}
          </p>
        </div>
        <div className="flex 4k:flex-1">
          <img
            src="/assets/icons/ic-location.svg"
            className="mr-[10px]"
            alt=""
          />
          <p className="text-bodysmall tablet:text-bodysmall text-ambit-light text-secondary-600 4k:text-h4">
            {city ?? 'Unknown'}
          </p>
        </div>
      </div>
      <div className="h-[1px] bg-secondary-300 "></div>
      <div className="card-spec">
        <ul className="space-y-[12px] 4k:space-y-[32px]">
          {specs.map((spec, index) => {
            return (
              <li key={index} className="flex items-center">
                <div className="w-[14px] h-[14px] 4k:scale-[2] 4k:mr-[20px] bg-primary rounded-full flex items-center justify-center mr-[10px]">
                  <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                </div>
                <span className="text-bodymedium tablet:text-bodysmall text-ambit-light text-secondary-900 4k:text-h3">
                  {spec}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="card-footer space-y-[28px]">
        {/* <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-secondary-900 4k:text-h2">
          {description}
        </p> */}
        <Link
          to={`/job-board/${id}`}
          className="block tablet:text-bodysmall text-center py-[12px] rounded-[6px] w-full bg-button text-white font-semibold text-ambit-semibold 4k:text-h3 4k:py-[24px]"
        >
          View Job
        </Link>
      </div>
    </div>
  )
}

export default JobCard
