export const paginate = function (
  array: Array<any>,
  index: number,
  size: number
) {
  // transform values
  index = Math.abs(index)
  index = index > 0 ? index - 1 : index
  size = size < 1 ? 1 : size

  // filter
  return [
    ...array.filter((value, n) => {
      return n >= index * size && n < (index + 1) * size
    })
  ]
}
